/* tslint:disable */
/* eslint-disable */
/**
 * Analysis Specification for Meta-analysis
 * api to create a meta-analysis specification
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jamesdkent21@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * a holder/reference to the annotation on neurostore
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * the id of the annotation on neurostore
     * @type {string}
     * @memberof Annotation
     */
    'neurostore_id'?: string;
    /**
     * the snapshot taken of the annotation pending a successful run of the meta-analytic algorithm
     * @type {object}
     * @memberof Annotation
     */
    'snapshot'?: object | null;
    /**
     * The related cached studyset to this annotation.
     * @type {string}
     * @memberof Annotation
     */
    'studyset'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'neurostore_url'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationList
 */
export interface AnnotationList {
    /**
     * 
     * @type {Array<AnnotationReturn>}
     * @memberof AnnotationList
     */
    'results'?: Array<AnnotationReturn>;
    /**
     * 
     * @type {object}
     * @memberof AnnotationList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface AnnotationPostBody
 */
export interface AnnotationPostBody {
    /**
     * 
     * @type {string}
     * @memberof AnnotationPostBody
     */
    'cached_studyset_id': string;
    /**
     * the id of the annotation on neurostore
     * @type {string}
     * @memberof AnnotationPostBody
     */
    'neurostore_id'?: string;
    /**
     * the snapshot taken of the annotation pending a successful run of the meta-analytic algorithm
     * @type {object}
     * @memberof AnnotationPostBody
     */
    'snapshot'?: object | null;
    /**
     * The related cached studyset to this annotation.
     * @type {string}
     * @memberof AnnotationPostBody
     */
    'studyset'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationPostBody
     */
    'neurostore_url'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationReturn
 */
export interface AnnotationReturn {
    /**
     * the id of the annotation on neurostore
     * @type {string}
     * @memberof AnnotationReturn
     */
    'neurostore_id'?: string;
    /**
     * the snapshot taken of the annotation pending a successful run of the meta-analytic algorithm
     * @type {object}
     * @memberof AnnotationReturn
     */
    'snapshot'?: object | null;
    /**
     * The related cached studyset to this annotation.
     * @type {string}
     * @memberof AnnotationReturn
     */
    'studyset'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturn
     */
    'neurostore_url'?: string;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof AnnotationReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof AnnotationReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof AnnotationReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof AnnotationReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturn
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnotationUpdate
 */
export interface AnnotationUpdate {
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdate
     */
    'cached_studyset_id'?: string;
    /**
     * the id of the annotation on neurostore
     * @type {string}
     * @memberof AnnotationUpdate
     */
    'neurostore_id'?: string;
    /**
     * the snapshot taken of the annotation pending a successful run of the meta-analytic algorithm
     * @type {object}
     * @memberof AnnotationUpdate
     */
    'snapshot'?: object | null;
    /**
     * The related cached studyset to this annotation.
     * @type {string}
     * @memberof AnnotationUpdate
     */
    'studyset'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdate
     */
    'neurostore_url'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationUpdateAllOf
 */
export interface AnnotationUpdateAllOf {
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdateAllOf
     */
    'cached_studyset_id'?: string;
}
/**
 * The function/class applying statistical adjustments to the output of the meta-analysis (optional).
 * @export
 * @interface Corrector
 */
export interface Corrector {
    /**
     * the name of the function/class performing the correction. For example FWECorrector from NiMARE would be valid.
     * @type {string}
     * @memberof Corrector
     */
    'type'?: string;
    /**
     * key word arguments passed to the corrector to modidy default functionality, such as number of iterations, or the particular method of correction being applied.
     * @type {object}
     * @memberof Corrector
     */
    'args'?: object;
}
/**
 * the specification for the function/class running the meta-analysis
 * @export
 * @interface Estimator
 */
export interface Estimator {
    /**
     * the meta-analytic algorithm applied to the data. Currently this should be directly tied to the function/class running the meta-analysis. For example, ALE, or MKDADensity are valid NiMARE classes to put here.
     * @type {string}
     * @memberof Estimator
     */
    'type'?: string;
    /**
     * arbitrary keyword arguments to be passed into the function/class to modify default functionality, this could modify the kernel, resampling methods, or any other behavior defined in the function/class (like MKDADensity).
     * @type {object}
     * @memberof Estimator
     */
    'args'?: object;
}
/**
 * 
 * @export
 * @interface MetaAnalysesGet400Response
 */
export interface MetaAnalysesGet400Response {
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysesGet400Response
     */
    'detail'?: string;
    /**
     * 
     * @type {number}
     * @memberof MetaAnalysesGet400Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysesGet400Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysesGet400Response
     */
    'type'?: string;
}
/**
 * The combination of the specification determining what meta-analysis to run (required), the studyset to act as input to the meta-analytic algorithm (required), and the annotation to provide human readable annotations as well as acts as an optional filter on which analyses to select within the studyset (optional, but suggested).
 * @export
 * @interface MetaAnalysis
 */
export interface MetaAnalysis {
    /**
     * 
     * @type {MetaAnalysisSpecification}
     * @memberof MetaAnalysis
     */
    'specification'?: MetaAnalysisSpecification;
    /**
     * 
     * @type {MetaAnalysisStudyset}
     * @memberof MetaAnalysis
     */
    'studyset'?: MetaAnalysisStudyset;
    /**
     * 
     * @type {MetaAnalysisAnnotation}
     * @memberof MetaAnalysis
     */
    'annotation'?: MetaAnalysisAnnotation;
    /**
     * Human-readable name of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysis
     */
    'name'?: string | null;
    /**
     * Long form description of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysis
     */
    'description'?: string | null;
    /**
     * The id of the studyset on neurosynth-compose (as opposed to the id of the studyset on neurostore). Multiple snapshots of the studyset can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cached_studyset_id'?: string;
    /**
     * The id of the annotation on neurosynth-compose (as opposed to the id of the annotation on neurostore). Multiple snapshots of the annotation can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cached_annotation_id'?: string;
    /**
     * 
     * @type {MetaAnalysisResults}
     * @memberof MetaAnalysis
     */
    'results'?: MetaAnalysisResults;
    /**
     * 
     * @type {object}
     * @memberof MetaAnalysis
     */
    'provenance'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'project'?: string | null;
    /**
     * a special key used to upload the results of this meta analysis. Can be used as an alternative to using your auth token from login. 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'run_key'?: string;
    /**
     * 
     * @type {NeurostoreAnalysis}
     * @memberof MetaAnalysis
     */
    'neurostore_analysis'?: NeurostoreAnalysis;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cognitive_contrast_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cognitive_contrast_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cognitive_paradigm_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cognitive_paradigm_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cached_studyset'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'cached_annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysis
     */
    'neurostore_url'?: string | null;
}
/**
 * @type MetaAnalysisAnnotation
 * @export
 */
export type MetaAnalysisAnnotation = Annotation | string;

/**
 * 
 * @export
 * @interface MetaAnalysisList
 */
export interface MetaAnalysisList {
    /**
     * 
     * @type {Array<MetaAnalysisReturn>}
     * @memberof MetaAnalysisList
     */
    'results'?: Array<MetaAnalysisReturn>;
    /**
     * 
     * @type {object}
     * @memberof MetaAnalysisList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface MetaAnalysisPostBody
 */
export interface MetaAnalysisPostBody {
    /**
     * 
     * @type {MetaAnalysisSpecification}
     * @memberof MetaAnalysisPostBody
     */
    'specification': MetaAnalysisSpecification;
    /**
     * 
     * @type {MetaAnalysisStudyset}
     * @memberof MetaAnalysisPostBody
     */
    'studyset'?: MetaAnalysisStudyset;
    /**
     * 
     * @type {MetaAnalysisAnnotation}
     * @memberof MetaAnalysisPostBody
     */
    'annotation'?: MetaAnalysisAnnotation;
    /**
     * Human-readable name of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'name'?: string | null;
    /**
     * Long form description of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'description'?: string | null;
    /**
     * The id of the studyset on neurosynth-compose (as opposed to the id of the studyset on neurostore). Multiple snapshots of the studyset can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cached_studyset_id': string;
    /**
     * The id of the annotation on neurosynth-compose (as opposed to the id of the annotation on neurostore). Multiple snapshots of the annotation can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cached_annotation_id': string;
    /**
     * 
     * @type {MetaAnalysisResults}
     * @memberof MetaAnalysisPostBody
     */
    'results'?: MetaAnalysisResults;
    /**
     * 
     * @type {object}
     * @memberof MetaAnalysisPostBody
     */
    'provenance'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'project'?: string | null;
    /**
     * a special key used to upload the results of this meta analysis. Can be used as an alternative to using your auth token from login. 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'run_key'?: string;
    /**
     * 
     * @type {NeurostoreAnalysis}
     * @memberof MetaAnalysisPostBody
     */
    'neurostore_analysis'?: NeurostoreAnalysis;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cognitive_contrast_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cognitive_contrast_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cognitive_paradigm_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cognitive_paradigm_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cached_studyset'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'cached_annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisPostBody
     */
    'neurostore_url'?: string | null;
}
/**
 * @type MetaAnalysisResults
 * array of neurostore ids representing the results of this meta-analysis (nominally all results should be the same, but machine architecture differences/algorithm stochastic-ness may lead to slightly different outcomes for each result.
 * @export
 */
export type MetaAnalysisResults = Array<ResultReturn> | Array<string>;

/**
 * 
 * @export
 * @interface MetaAnalysisReturn
 */
export interface MetaAnalysisReturn {
    /**
     * 
     * @type {MetaAnalysisSpecification}
     * @memberof MetaAnalysisReturn
     */
    'specification'?: MetaAnalysisSpecification;
    /**
     * 
     * @type {MetaAnalysisStudyset}
     * @memberof MetaAnalysisReturn
     */
    'studyset'?: MetaAnalysisStudyset;
    /**
     * 
     * @type {MetaAnalysisAnnotation}
     * @memberof MetaAnalysisReturn
     */
    'annotation'?: MetaAnalysisAnnotation;
    /**
     * Human-readable name of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'name'?: string | null;
    /**
     * Long form description of the meta-analysis.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'description'?: string | null;
    /**
     * The id of the studyset on neurosynth-compose (as opposed to the id of the studyset on neurostore). Multiple snapshots of the studyset can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cached_studyset_id'?: string;
    /**
     * The id of the annotation on neurosynth-compose (as opposed to the id of the annotation on neurostore). Multiple snapshots of the annotation can be stored on neurosynth-compose so knowing which snapshot is being referenced is necessary.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cached_annotation_id'?: string;
    /**
     * 
     * @type {MetaAnalysisResults}
     * @memberof MetaAnalysisReturn
     */
    'results'?: MetaAnalysisResults;
    /**
     * 
     * @type {object}
     * @memberof MetaAnalysisReturn
     */
    'provenance'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'project'?: string | null;
    /**
     * a special key used to upload the results of this meta analysis. Can be used as an alternative to using your auth token from login. 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'run_key'?: string;
    /**
     * 
     * @type {NeurostoreAnalysis}
     * @memberof MetaAnalysisReturn
     */
    'neurostore_analysis'?: NeurostoreAnalysis;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cognitive_contrast_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cognitive_contrast_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cognitive_paradigm_cogatlas'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cognitive_paradigm_cogatlas_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cached_studyset'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'cached_annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'neurostore_url'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MetaAnalysisReturn
     */
    'username'?: string | null;
}
/**
 * @type MetaAnalysisSpecification
 * @export
 */
export type MetaAnalysisSpecification = Specification | string;

/**
 * @type MetaAnalysisStudyset
 * @export
 */
export type MetaAnalysisStudyset = Studyset | string;

/**
 * 
 * @export
 * @interface NeurostoreAnalysis
 */
export interface NeurostoreAnalysis {
    /**
     * 
     * @type {string}
     * @memberof NeurostoreAnalysis
     */
    'neurostore_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreAnalysis
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreAnalysis
     */
    'traceback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreAnalysis
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurostoreStudy
 */
export interface NeurostoreStudy {
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudy
     */
    'neurostore_id'?: string | null;
    /**
     * 
     * @type {Array<NeurostoreAnalysis>}
     * @memberof NeurostoreStudy
     */
    'analyses'?: Array<NeurostoreAnalysis>;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudy
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudy
     */
    'traceback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudy
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurostoreStudyList
 */
export interface NeurostoreStudyList {
    /**
     * 
     * @type {Array<NeurostoreStudyReturn>}
     * @memberof NeurostoreStudyList
     */
    'results'?: Array<NeurostoreStudyReturn>;
    /**
     * 
     * @type {object}
     * @memberof NeurostoreStudyList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface NeurostoreStudyReturn
 */
export interface NeurostoreStudyReturn {
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'neurostore_id'?: string | null;
    /**
     * 
     * @type {Array<NeurostoreAnalysis>}
     * @memberof NeurostoreStudyReturn
     */
    'analyses'?: Array<NeurostoreAnalysis>;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'traceback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'status'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurostoreStudyReturn
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurovaultCollection
 */
export interface NeurovaultCollection {
    /**
     * 
     * @type {string}
     * @memberof NeurovaultCollection
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {NeurovaultCollectionFiles}
     * @memberof NeurovaultCollection
     */
    'files'?: NeurovaultCollectionFiles;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultCollection
     */
    'url'?: string | null;
}
/**
 * @type NeurovaultCollectionFiles
 * @export
 */
export type NeurovaultCollectionFiles = Array<NeurovaultFile> | Array<string>;

/**
 * 
 * @export
 * @interface NeurovaultCollectionReturn
 */
export interface NeurovaultCollectionReturn {
    /**
     * 
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {NeurovaultCollectionFiles}
     * @memberof NeurovaultCollectionReturn
     */
    'files'?: NeurovaultCollectionFiles;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'url'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultCollectionReturn
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurovaultFile
 */
export interface NeurovaultFile {
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'traceback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'image_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFile
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurovaultFileList
 */
export interface NeurovaultFileList {
    /**
     * 
     * @type {Array<NeurovaultFileReturn>}
     * @memberof NeurovaultFileList
     */
    'results'?: Array<NeurovaultFileReturn>;
    /**
     * 
     * @type {object}
     * @memberof NeurovaultFileList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface NeurovaultFileReturn
 */
export interface NeurovaultFileReturn {
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'traceback'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'image_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'url'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NeurovaultFileReturn
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface NeurovaultList
 */
export interface NeurovaultList {
    /**
     * 
     * @type {Array<NeurovaultCollectionReturn>}
     * @memberof NeurovaultList
     */
    'results'?: Array<NeurovaultCollectionReturn>;
    /**
     * 
     * @type {object}
     * @memberof NeurovaultList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {object}
     * @memberof Project
     */
    'provenance'?: object | null;
    /**
     * 
     * @type {ProjectMetaAnalyses}
     * @memberof Project
     */
    'meta_analyses'?: ProjectMetaAnalyses;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'description'?: string | null;
    /**
     * whether the project is public or private
     * @type {boolean}
     * @memberof Project
     */
    'public'?: boolean;
    /**
     * 
     * @type {NeurostoreStudy}
     * @memberof Project
     */
    'neurostore_study'?: NeurostoreStudy;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'neurostore_url'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectList
 */
export interface ProjectList {
    /**
     * 
     * @type {Array<ProjectReturn>}
     * @memberof ProjectList
     */
    'results'?: Array<ProjectReturn>;
    /**
     * 
     * @type {object}
     * @memberof ProjectList
     */
    'metadata'?: object;
}
/**
 * @type ProjectMetaAnalyses
 * @export
 */
export type ProjectMetaAnalyses = Array<MetaAnalysis> | Array<string>;

/**
 * 
 * @export
 * @interface ProjectReturn
 */
export interface ProjectReturn {
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof ProjectReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof ProjectReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof ProjectReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof ProjectReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectReturn
     */
    'username'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof ProjectReturn
     */
    'provenance'?: object | null;
    /**
     * 
     * @type {ProjectMetaAnalyses}
     * @memberof ProjectReturn
     */
    'meta_analyses'?: ProjectMetaAnalyses;
    /**
     * 
     * @type {string}
     * @memberof ProjectReturn
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectReturn
     */
    'description'?: string | null;
    /**
     * whether the project is public or private
     * @type {boolean}
     * @memberof ProjectReturn
     */
    'public'?: boolean;
    /**
     * 
     * @type {NeurostoreStudy}
     * @memberof ProjectReturn
     */
    'neurostore_study'?: NeurostoreStudy;
    /**
     * 
     * @type {string}
     * @memberof ProjectReturn
     */
    'neurostore_url'?: string | null;
}
/**
 * 
 * @export
 * @interface ReadOnly
 */
export interface ReadOnly {
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof ReadOnly
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof ReadOnly
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof ReadOnly
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof ReadOnly
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReadOnly
     */
    'username'?: string | null;
}
/**
 * describes the output of a meta-analysis
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * the meta analysis this result was derived from.
     * @type {string}
     * @memberof Result
     */
    'meta_analysis_id'?: string;
    /**
     * version of the command-line-tool that is uploading the results. 
     * @type {string}
     * @memberof Result
     */
    'cli_version'?: string | null;
    /**
     * 
     * @type {NeurovaultCollectionReturn}
     * @memberof Result
     */
    'neurovault_collection'?: NeurovaultCollectionReturn;
    /**
     * the description of the methods applied to create this result.
     * @type {string}
     * @memberof Result
     */
    'methods_description'?: string | null;
    /**
     * a text representation of a tsv that marks the contribution of each study to each particular cluster.
     * @type {string}
     * @memberof Result
     */
    'diagnostic_table'?: string | null;
    /**
     * additional parameters that were passed to the commandline tool at runtime. 
     * @type {object}
     * @memberof Result
     */
    'cli_args'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface ResultInit
 */
export interface ResultInit {
    /**
     * 
     * @type {string}
     * @memberof ResultInit
     */
    'meta_analysis_id'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResultInit
     */
    'studyset_snapshot'?: object;
    /**
     * 
     * @type {object}
     * @memberof ResultInit
     */
    'annotation_snapshot'?: object;
    /**
     * 
     * @type {string}
     * @memberof ResultInit
     */
    'cli_version'?: string;
}
/**
 * 
 * @export
 * @interface ResultList
 */
export interface ResultList {
    /**
     * 
     * @type {ResultListResults}
     * @memberof ResultList
     */
    'results'?: ResultListResults;
    /**
     * 
     * @type {object}
     * @memberof ResultList
     */
    'metadata'?: object;
}
/**
 * @type ResultListResults
 * @export
 */
export type ResultListResults = Array<ResultReturn> | Array<string>;

/**
 * 
 * @export
 * @interface ResultReturn
 */
export interface ResultReturn {
    /**
     * the meta analysis this result was derived from.
     * @type {string}
     * @memberof ResultReturn
     */
    'meta_analysis_id'?: string;
    /**
     * version of the command-line-tool that is uploading the results. 
     * @type {string}
     * @memberof ResultReturn
     */
    'cli_version'?: string | null;
    /**
     * 
     * @type {NeurovaultCollectionReturn}
     * @memberof ResultReturn
     */
    'neurovault_collection'?: NeurovaultCollectionReturn;
    /**
     * the description of the methods applied to create this result.
     * @type {string}
     * @memberof ResultReturn
     */
    'methods_description'?: string | null;
    /**
     * a text representation of a tsv that marks the contribution of each study to each particular cluster.
     * @type {string}
     * @memberof ResultReturn
     */
    'diagnostic_table'?: string | null;
    /**
     * additional parameters that were passed to the commandline tool at runtime. 
     * @type {object}
     * @memberof ResultReturn
     */
    'cli_args'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ResultReturn
     */
    'status'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof ResultReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof ResultReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof ResultReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof ResultReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResultReturn
     */
    'username'?: string | null;
}
/**
 * a machine readable specification of how to run a meta-analysis (currently specifically tailored to NiMARE).
 * @export
 * @interface Specification
 */
export interface Specification {
    /**
     * the type of meta-analysis being run, typically either cbma or ibma, but others may become available in the future.
     * @type {string}
     * @memberof Specification
     */
    'type'?: string;
    /**
     * 
     * @type {Estimator}
     * @memberof Specification
     */
    'estimator'?: Estimator;
    /**
     * a string representing a binary nifti file to select which voxels a user wants to include in the analysis.
     * @type {string}
     * @memberof Specification
     */
    'mask'?: string | null;
    /**
     * 
     * @type {SpecificationConditions}
     * @memberof Specification
     */
    'conditions'?: SpecificationConditions;
    /**
     * 
     * @type {Array<number>}
     * @memberof Specification
     */
    'weights'?: Array<number> | null;
    /**
     * A transformation applied to column(s) (e.g., binarize based on a threshold). This is likely to become deprecated.
     * @type {string}
     * @memberof Specification
     */
    'transformer'?: string | null;
    /**
     * 
     * @type {Corrector}
     * @memberof Specification
     */
    'corrector'?: Corrector | null;
    /**
     * a column from annotations selecting which analyses to include in the meta-analysis
     * @type {string}
     * @memberof Specification
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Specification
     */
    'database_studyset'?: string | null;
}
/**
 * @type SpecificationConditions
 * selection of categories in the filter column to differentiate groups, or \"neurosynth\", \"neuroquery\", or \"neurostore\" to compare to a database reference group
 * @export
 */
export type SpecificationConditions = Array<boolean> | Array<string>;

/**
 * The representation of a list of specifications.
 * @export
 * @interface SpecificationList
 */
export interface SpecificationList {
    /**
     * 
     * @type {Array<SpecificationReturn>}
     * @memberof SpecificationList
     */
    'results'?: Array<SpecificationReturn>;
    /**
     * 
     * @type {object}
     * @memberof SpecificationList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface SpecificationPostBody
 */
export interface SpecificationPostBody {
    /**
     * the type of meta-analysis being run, typically either cbma or ibma, but others may become available in the future.
     * @type {string}
     * @memberof SpecificationPostBody
     */
    'type'?: string;
    /**
     * 
     * @type {Estimator}
     * @memberof SpecificationPostBody
     */
    'estimator'?: Estimator;
    /**
     * a string representing a binary nifti file to select which voxels a user wants to include in the analysis.
     * @type {string}
     * @memberof SpecificationPostBody
     */
    'mask'?: string | null;
    /**
     * 
     * @type {SpecificationConditions}
     * @memberof SpecificationPostBody
     */
    'conditions'?: SpecificationConditions;
    /**
     * 
     * @type {Array<number>}
     * @memberof SpecificationPostBody
     */
    'weights'?: Array<number> | null;
    /**
     * A transformation applied to column(s) (e.g., binarize based on a threshold). This is likely to become deprecated.
     * @type {string}
     * @memberof SpecificationPostBody
     */
    'transformer'?: string | null;
    /**
     * 
     * @type {Corrector}
     * @memberof SpecificationPostBody
     */
    'corrector'?: Corrector | null;
    /**
     * a column from annotations selecting which analyses to include in the meta-analysis
     * @type {string}
     * @memberof SpecificationPostBody
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecificationPostBody
     */
    'database_studyset'?: string | null;
}
/**
 * The view of the specification through an endpoint.
 * @export
 * @interface SpecificationReturn
 */
export interface SpecificationReturn {
    /**
     * the type of meta-analysis being run, typically either cbma or ibma, but others may become available in the future.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'type'?: string;
    /**
     * 
     * @type {Estimator}
     * @memberof SpecificationReturn
     */
    'estimator'?: Estimator;
    /**
     * a string representing a binary nifti file to select which voxels a user wants to include in the analysis.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'mask'?: string | null;
    /**
     * 
     * @type {SpecificationConditions}
     * @memberof SpecificationReturn
     */
    'conditions'?: SpecificationConditions;
    /**
     * 
     * @type {Array<number>}
     * @memberof SpecificationReturn
     */
    'weights'?: Array<number> | null;
    /**
     * A transformation applied to column(s) (e.g., binarize based on a threshold). This is likely to become deprecated.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'transformer'?: string | null;
    /**
     * 
     * @type {Corrector}
     * @memberof SpecificationReturn
     */
    'corrector'?: Corrector | null;
    /**
     * a column from annotations selecting which analyses to include in the meta-analysis
     * @type {string}
     * @memberof SpecificationReturn
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecificationReturn
     */
    'database_studyset'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof SpecificationReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecificationReturn
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface Studyset
 */
export interface Studyset {
    /**
     * The id of the studyset on neurostore.
     * @type {string}
     * @memberof Studyset
     */
    'neurostore_id'?: string;
    /**
     * The snapshot of the studyset pending a successful run of the meta-analysis.
     * @type {object}
     * @memberof Studyset
     */
    'snapshot'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof Studyset
     */
    'neurostore_url'?: string;
    /**
     * A string representing a labeled version of this particular studyset.
     * @type {string}
     * @memberof Studyset
     */
    'version'?: string | null;
}
/**
 * 
 * @export
 * @interface StudysetList
 */
export interface StudysetList {
    /**
     * 
     * @type {Array<StudysetReturn>}
     * @memberof StudysetList
     */
    'results'?: Array<StudysetReturn>;
    /**
     * 
     * @type {object}
     * @memberof StudysetList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface StudysetPostBody
 */
export interface StudysetPostBody {
    /**
     * The id of the studyset on neurostore.
     * @type {string}
     * @memberof StudysetPostBody
     */
    'neurostore_id': string;
    /**
     * The snapshot of the studyset pending a successful run of the meta-analysis.
     * @type {object}
     * @memberof StudysetPostBody
     */
    'snapshot'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetPostBody
     */
    'neurostore_url'?: string;
    /**
     * A string representing a labeled version of this particular studyset.
     * @type {string}
     * @memberof StudysetPostBody
     */
    'version'?: string | null;
}
/**
 * 
 * @export
 * @interface StudysetReference
 */
export interface StudysetReference {
    /**
     * 
     * @type {Array<StudysetReferenceSnapshotsInner>}
     * @memberof StudysetReference
     */
    'snapshots'?: Array<StudysetReferenceSnapshotsInner>;
}
/**
 * 
 * @export
 * @interface StudysetReferenceList
 */
export interface StudysetReferenceList {
    /**
     * 
     * @type {Array<StudysetReferenceReturn>}
     * @memberof StudysetReferenceList
     */
    'results'?: Array<StudysetReferenceReturn>;
    /**
     * 
     * @type {object}
     * @memberof StudysetReferenceList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface StudysetReferenceReturn
 */
export interface StudysetReferenceReturn {
    /**
     * 
     * @type {Array<StudysetReferenceSnapshotsInner>}
     * @memberof StudysetReferenceReturn
     */
    'snapshots'?: Array<StudysetReferenceSnapshotsInner>;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof StudysetReferenceReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof StudysetReferenceReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof StudysetReferenceReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof StudysetReferenceReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReferenceReturn
     */
    'username'?: string | null;
}
/**
 * @type StudysetReferenceSnapshotsInner
 * @export
 */
export type StudysetReferenceSnapshotsInner = Studyset | string;

/**
 * 
 * @export
 * @interface StudysetReturn
 */
export interface StudysetReturn {
    /**
     * The id of the studyset on neurostore.
     * @type {string}
     * @memberof StudysetReturn
     */
    'neurostore_id'?: string;
    /**
     * The snapshot of the studyset pending a successful run of the meta-analysis.
     * @type {object}
     * @memberof StudysetReturn
     */
    'snapshot'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'neurostore_url'?: string;
    /**
     * A string representing a labeled version of this particular studyset.
     * @type {string}
     * @memberof StudysetReturn
     */
    'version'?: string | null;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof StudysetReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof StudysetReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof StudysetReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof StudysetReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'username'?: string | null;
}
/**
 * attributes of the user object
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<UserReturn>}
     * @memberof UserList
     */
    'results'?: Array<UserReturn>;
    /**
     * 
     * @type {object}
     * @memberof UserList
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface UserReturn
 */
export interface UserReturn {
    /**
     * 
     * @type {string}
     * @memberof UserReturn
     */
    'external_id'?: string;
    /**
     * the identifier for the resource.
     * @type {string}
     * @memberof UserReturn
     */
    'id'?: string;
    /**
     * when the resource was last modified.
     * @type {string}
     * @memberof UserReturn
     */
    'updated_at'?: string | null;
    /**
     * When the resource was created.
     * @type {string}
     * @memberof UserReturn
     */
    'created_at'?: string;
    /**
     * Who owns the resource.
     * @type {string}
     * @memberof UserReturn
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReturn
     */
    'username'?: string | null;
}

/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdGet', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut: async (id: string, annotationUpdate?: AnnotationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdPut', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost: async (annotationPostBody?: AnnotationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationsApiAxiosParamCreator(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdPut(id, annotationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsPost(annotationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationsApiFp(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet(options?: any): AxiosPromise<AnnotationList> {
            return localVarFp.annotationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet(id: string, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdPut(id, annotationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost(annotationPostBody?: AnnotationPostBody, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsPost(annotationPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
    /**
     * get a list of serialized/referenced annotations
     * @summary GET a list of annotations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsGet(options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single annotation
     * @summary GET information about an annotation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsIdGet(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing annotation
     * @summary Update an Annotation
     * @param {string} id 
     * @param {AnnotationUpdate} [annotationUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsIdPut(id, annotationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new serialized/referenced annotation
     * @summary Create a new Annotation
     * @param {AnnotationPostBody} [annotationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsPost(annotationPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComposeApi - axios parameter creator
 * @export
 */
export const ComposeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdGet', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut: async (id: string, annotationUpdate?: AnnotationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdPut', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost: async (annotationPostBody?: AnnotationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet: async (nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdGet', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut: async (id: string, metaAnalysis?: MetaAnalysis, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdPut', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost: async (metaAnalysisPostBody?: MetaAnalysisPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysisPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet: async (metaAnalysisId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metaAnalysisId !== undefined) {
                localVarQueryParameter['meta_analysis_id'] = metaAnalysisId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdGet', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut: async (id: string, result?: Result, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdPut', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(result, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost: async (resultInit?: ResultInit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resultInit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdGet', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdPut', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost: async (neurovaultCollection?: NeurovaultCollection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdGet', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut: async (id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdPut', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (collectionId !== undefined) { 
                localVarFormParams.append('collection_id', collectionId as any);
            }
    
            if (exception !== undefined) { 
                localVarFormParams.append('exception', exception as any);
            }
    
            if (traceback !== undefined) { 
                localVarFormParams.append('traceback', traceback as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (imageId !== undefined) { 
                localVarFormParams.append('image_id', imageId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost: async (neurovaultFile?: NeurovaultFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet: async (page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdDelete', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet: async (id: string, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdGet', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut: async (id: string, project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPut', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdGet', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut: async (id: string, specification?: Specification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdPut', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost: async (specificationPostBody?: SpecificationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specificationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdGet', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut: async (id: string, studyset?: Studyset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdPut', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost: async (studysetPostBody?: StudysetPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComposeApi - functional programming interface
 * @export
 */
export const ComposeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComposeApiAxiosParamCreator(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdPut(id, annotationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsPost(annotationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdPut(id, metaAnalysis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesPost(metaAnalysisPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsGet(metaAnalysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdPut(id, result, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsPost(resultInit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsPost(neurovaultCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesPost(neurovaultFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdGet(id, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPut(id, project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdPut(id, specification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsPost(specificationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdPut(id, studyset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsPost(studysetPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComposeApi - factory interface
 * @export
 */
export const ComposeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComposeApiFp(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet(options?: any): AxiosPromise<AnnotationList> {
            return localVarFp.annotationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet(id: string, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdPut(id, annotationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost(annotationPostBody?: AnnotationPostBody, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsPost(annotationPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: any): AxiosPromise<MetaAnalysisList> {
            return localVarFp.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet(metaAnalysisId?: string, options?: any): AxiosPromise<ResultList> {
            return localVarFp.metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet(id: string, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut(id: string, result?: Result, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdPut(id, result, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost(resultInit?: ResultInit, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsPost(resultInit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet(options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet(options?: any): AxiosPromise<NeurovaultFileList> {
            return localVarFp.neurovaultFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet(id: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesPost(neurovaultFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: any): AxiosPromise<ProjectList> {
            return localVarFp.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet(id: string, info?: boolean, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdGet(id, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut(id: string, project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdPut(id, project, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsPost(project, options).then((request) => request(axios, basePath));
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet(options?: any): AxiosPromise<SpecificationList> {
            return localVarFp.specificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet(id: string, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut(id: string, specification?: Specification, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdPut(id, specification, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost(specificationPostBody?: SpecificationPostBody, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsPost(specificationPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet(options?: any): AxiosPromise<StudysetList> {
            return localVarFp.studysetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet(id: string, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut(id: string, studyset?: Studyset, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdPut(id, studyset, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost(studysetPostBody?: StudysetPostBody, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsPost(studysetPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComposeApi - object-oriented interface
 * @export
 * @class ComposeApi
 * @extends {BaseAPI}
 */
export class ComposeApi extends BaseAPI {
    /**
     * get a list of serialized/referenced annotations
     * @summary GET a list of annotations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public annotationsGet(options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).annotationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single annotation
     * @summary GET information about an annotation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public annotationsIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).annotationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing annotation
     * @summary Update an Annotation
     * @param {string} id 
     * @param {AnnotationUpdate} [annotationUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).annotationsIdPut(id, annotationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new serialized/referenced annotation
     * @summary Create a new Annotation
     * @param {AnnotationPostBody} [annotationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).annotationsPost(annotationPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all runnable specification, studyset, annotation bundles
     * @summary GET a list of meta-analyses
     * @param {boolean} [nested] show nested component instead of id
     * @param {Array<string>} [ids] choose the specific ids you wish to get
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis (specification, annotation, and studyset)
     * @summary GET meta-analysis information
     * @param {string} id 
     * @param {boolean} [nested] show nested component instead of id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta-analysis (that has not yet been run)
     * @summary Update a meta-analysis
     * @param {string} id 
     * @param {MetaAnalysis} [metaAnalysis] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new specification, studyset, annotation bundle
     * @summary Create a new meta-analysis
     * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} [metaAnalysisId] search for results with this meta-analysis id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysisResultsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Result} [result] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysisResultsIdPut(id, result, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ResultInit} [resultInit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).metaAnalysisResultsPost(resultInit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get neurovault collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultCollectionsGet(options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultCollectionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultCollectionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultCollectionsIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create neurovault collection
     * @param {NeurovaultCollection} [neurovaultCollection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultFilesGet(options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultFilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} [collectionId] 
     * @param {string} [exception] 
     * @param {string} [traceback] 
     * @param {string} [status] 
     * @param {string} [imageId] 
     * @param {string} [name] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {NeurovaultFile} [neurovaultFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).neurovaultFilesPost(neurovaultFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {string} [userId] user id you want to filter on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public projectsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).projectsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).projectsIdGet(id, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).projectsIdPut(id, project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public projectsPost(project?: Project, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).projectsPost(project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list of meta-analysis specifications
     * @summary Get a list of Specifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public specificationsGet(options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).specificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis specification
     * @summary Get information about a Specification
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public specificationsIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).specificationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta analysis specification
     * @summary Update Meta-Analysis specification
     * @param {string} id 
     * @param {Specification} [specification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).specificationsIdPut(id, specification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new meta-analysis specification
     * @summary Create a Specification
     * @param {SpecificationPostBody} [specificationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).specificationsPost(specificationPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a list of serialized/referenced studysets
     * @summary Get a list of Studysets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public studysetsGet(options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).studysetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single serialized/referenced studyset
     * @summary Get information about a Studyset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public studysetsIdGet(id: string, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).studysetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing serialized/referenced studyset
     * @summary Update a Studyset
     * @param {string} id 
     * @param {Studyset} [studyset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).studysetsIdPut(id, studyset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new serialized/referenced studyset
     * @summary Create a new Studyset
     * @param {StudysetPostBody} [studysetPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComposeApi
     */
    public studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig) {
        return ComposeApiFp(this.configuration).studysetsPost(studysetPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdDelete', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurostore-studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurostoreStudiesIdGet', 'id', id)
            const localVarPath = `/neurostore-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurostoreStudiesIdPut', 'id', id)
            const localVarPath = `/neurostore-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurostore-studies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetReferencesGet: async (nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studyset-references`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetReferencesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetReferencesIdGet', 'id', id)
            const localVarPath = `/studyset-references/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurostoreStudiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurostoreStudyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurostoreStudiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurostoreStudiesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurostoreStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurostoreStudiesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurostoreStudiesIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurostoreStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurostoreStudiesIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurostoreStudiesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurostoreStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurostoreStudiesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetReferencesGet(nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReferenceList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetReferencesGet(nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetReferencesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReferenceReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetReferencesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.metaAnalysesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesGet(options?: any): AxiosPromise<NeurostoreStudyList> {
            return localVarFp.neurostoreStudiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesIdGet(id: string, options?: any): AxiosPromise<NeurostoreStudyReturn> {
            return localVarFp.neurostoreStudiesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesIdPut(id: string, options?: any): AxiosPromise<NeurostoreStudyReturn> {
            return localVarFp.neurostoreStudiesIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurostoreStudiesPost(options?: any): AxiosPromise<NeurostoreStudyReturn> {
            return localVarFp.neurostoreStudiesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetReferencesGet(nested?: boolean, options?: any): AxiosPromise<StudysetReferenceList> {
            return localVarFp.studysetReferencesGet(nested, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetReferencesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<StudysetReferenceReturn> {
            return localVarFp.studysetReferencesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metaAnalysesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).metaAnalysesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public neurostoreStudiesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).neurostoreStudiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public neurostoreStudiesIdGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).neurostoreStudiesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public neurostoreStudiesIdPut(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).neurostoreStudiesIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public neurostoreStudiesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).neurostoreStudiesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {boolean} [nested] show nested component instead of id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studysetReferencesGet(nested?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studysetReferencesGet(nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [nested] show nested component instead of id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studysetReferencesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studysetReferencesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetApi - axios parameter creator
 * @export
 */
export const GetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdGet', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet: async (nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdGet', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet: async (metaAnalysisId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metaAnalysisId !== undefined) {
                localVarQueryParameter['meta_analysis_id'] = metaAnalysisId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdGet', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdGet', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdGet', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet: async (page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet: async (id: string, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdGet', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdGet', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdGet', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetApi - functional programming interface
 * @export
 */
export const GetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetApiAxiosParamCreator(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsGet(metaAnalysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdGet(id, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetApi - factory interface
 * @export
 */
export const GetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetApiFp(configuration)
    return {
        /**
         * get a list of serialized/referenced annotations
         * @summary GET a list of annotations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet(options?: any): AxiosPromise<AnnotationList> {
            return localVarFp.annotationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single annotation
         * @summary GET information about an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet(id: string, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: any): AxiosPromise<MetaAnalysisList> {
            return localVarFp.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet(metaAnalysisId?: string, options?: any): AxiosPromise<ResultList> {
            return localVarFp.metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet(id: string, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet(options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet(options?: any): AxiosPromise<NeurovaultFileList> {
            return localVarFp.neurovaultFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet(id: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: any): AxiosPromise<ProjectList> {
            return localVarFp.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet(id: string, info?: boolean, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdGet(id, info, options).then((request) => request(axios, basePath));
        },
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet(options?: any): AxiosPromise<SpecificationList> {
            return localVarFp.specificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet(id: string, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet(options?: any): AxiosPromise<StudysetList> {
            return localVarFp.studysetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet(id: string, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetApi - object-oriented interface
 * @export
 * @class GetApi
 * @extends {BaseAPI}
 */
export class GetApi extends BaseAPI {
    /**
     * get a list of serialized/referenced annotations
     * @summary GET a list of annotations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public annotationsGet(options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).annotationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single annotation
     * @summary GET information about an annotation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public annotationsIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).annotationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list all runnable specification, studyset, annotation bundles
     * @summary GET a list of meta-analyses
     * @param {boolean} [nested] show nested component instead of id
     * @param {Array<string>} [ids] choose the specific ids you wish to get
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis (specification, annotation, and studyset)
     * @summary GET meta-analysis information
     * @param {string} id 
     * @param {boolean} [nested] show nested component instead of id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).metaAnalysesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} [metaAnalysisId] search for results with this meta-analysis id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).metaAnalysisResultsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get neurovault collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public neurovaultCollectionsGet(options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).neurovaultCollectionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).neurovaultCollectionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public neurovaultFilesGet(options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).neurovaultFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).neurovaultFilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {string} [userId] user id you want to filter on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).projectsIdGet(id, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list of meta-analysis specifications
     * @summary Get a list of Specifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public specificationsGet(options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).specificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis specification
     * @summary Get information about a Specification
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public specificationsIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).specificationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a list of serialized/referenced studysets
     * @summary Get a list of Studysets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public studysetsGet(options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).studysetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single serialized/referenced studyset
     * @summary Get information about a Studyset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetApi
     */
    public studysetsIdGet(id: string, options?: AxiosRequestConfig) {
        return GetApiFp(this.configuration).studysetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetaAnalysesApi - axios parameter creator
 * @export
 */
export const MetaAnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet: async (nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdGet', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut: async (id: string, metaAnalysis?: MetaAnalysis, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdPut', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost: async (metaAnalysisPostBody?: MetaAnalysisPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysisPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet: async (metaAnalysisId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metaAnalysisId !== undefined) {
                localVarQueryParameter['meta_analysis_id'] = metaAnalysisId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdGet', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut: async (id: string, result?: Result, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdPut', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(result, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost: async (resultInit?: ResultInit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resultInit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaAnalysesApi - functional programming interface
 * @export
 */
export const MetaAnalysesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetaAnalysesApiAxiosParamCreator(configuration)
    return {
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdPut(id, metaAnalysis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesPost(metaAnalysisPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsGet(metaAnalysisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdPut(id, result, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsPost(resultInit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetaAnalysesApi - factory interface
 * @export
 */
export const MetaAnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetaAnalysesApiFp(configuration)
    return {
        /**
         * list all runnable specification, studyset, annotation bundles
         * @summary GET a list of meta-analyses
         * @param {boolean} [nested] show nested component instead of id
         * @param {Array<string>} [ids] choose the specific ids you wish to get
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: any): AxiosPromise<MetaAnalysisList> {
            return localVarFp.metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis (specification, annotation, and studyset)
         * @summary GET meta-analysis information
         * @param {string} id 
         * @param {boolean} [nested] show nested component instead of id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} [metaAnalysisId] search for results with this meta-analysis id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsGet(metaAnalysisId?: string, options?: any): AxiosPromise<ResultList> {
            return localVarFp.metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdGet(id: string, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut(id: string, result?: Result, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdPut(id, result, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost(resultInit?: ResultInit, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsPost(resultInit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetaAnalysesApi - object-oriented interface
 * @export
 * @class MetaAnalysesApi
 * @extends {BaseAPI}
 */
export class MetaAnalysesApi extends BaseAPI {
    /**
     * list all runnable specification, studyset, annotation bundles
     * @summary GET a list of meta-analyses
     * @param {boolean} [nested] show nested component instead of id
     * @param {Array<string>} [ids] choose the specific ids you wish to get
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysesGet(nested?: boolean, ids?: Array<string>, page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysesGet(nested, ids, page, pageSize, name, search, description, sort, desc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis (specification, annotation, and studyset)
     * @summary GET meta-analysis information
     * @param {string} id 
     * @param {boolean} [nested] show nested component instead of id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta-analysis (that has not yet been run)
     * @summary Update a meta-analysis
     * @param {string} id 
     * @param {MetaAnalysis} [metaAnalysis] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new specification, studyset, annotation bundle
     * @summary Create a new meta-analysis
     * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} [metaAnalysisId] search for results with this meta-analysis id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysisResultsGet(metaAnalysisId?: string, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysisResultsGet(metaAnalysisId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysisResultsIdGet(id: string, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysisResultsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Result} [result] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysisResultsIdPut(id, result, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ResultInit} [resultInit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaAnalysesApi
     */
    public metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig) {
        return MetaAnalysesApiFp(this.configuration).metaAnalysisResultsPost(resultInit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NeurovaultApi - axios parameter creator
 * @export
 */
export const NeurovaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdGet', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdPut', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost: async (neurovaultCollection?: NeurovaultCollection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdGet', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut: async (id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdPut', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (collectionId !== undefined) { 
                localVarFormParams.append('collection_id', collectionId as any);
            }
    
            if (exception !== undefined) { 
                localVarFormParams.append('exception', exception as any);
            }
    
            if (traceback !== undefined) { 
                localVarFormParams.append('traceback', traceback as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (imageId !== undefined) { 
                localVarFormParams.append('image_id', imageId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost: async (neurovaultFile?: NeurovaultFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NeurovaultApi - functional programming interface
 * @export
 */
export const NeurovaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NeurovaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsPost(neurovaultCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesPost(neurovaultFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NeurovaultApi - factory interface
 * @export
 */
export const NeurovaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NeurovaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get neurovault collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsGet(options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdGet(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesGet(options?: any): AxiosPromise<NeurovaultFileList> {
            return localVarFp.neurovaultFilesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdGet(id: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesPost(neurovaultFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NeurovaultApi - object-oriented interface
 * @export
 * @class NeurovaultApi
 * @extends {BaseAPI}
 */
export class NeurovaultApi extends BaseAPI {
    /**
     * 
     * @summary Get neurovault collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultCollectionsGet(options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultCollectionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultCollectionsIdGet(id: string, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultCollectionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultCollectionsIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create neurovault collection
     * @param {NeurovaultCollection} [neurovaultCollection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultFilesGet(options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultFilesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultFilesIdGet(id: string, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultFilesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} [collectionId] 
     * @param {string} [exception] 
     * @param {string} [traceback] 
     * @param {string} [status] 
     * @param {string} [imageId] 
     * @param {string} [name] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {NeurovaultFile} [neurovaultFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NeurovaultApi
     */
    public neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig) {
        return NeurovaultApiFp(this.configuration).neurovaultFilesPost(neurovaultFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostApi - axios parameter creator
 * @export
 */
export const PostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost: async (annotationPostBody?: AnnotationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost: async (metaAnalysisPostBody?: MetaAnalysisPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysisPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost: async (resultInit?: ResultInit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meta-analysis-results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resultInit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost: async (neurovaultCollection?: NeurovaultCollection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultCollection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost: async (neurovaultFile?: NeurovaultFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/neurovault-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neurovaultFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost: async (specificationPostBody?: SpecificationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specificationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost: async (studysetPostBody?: StudysetPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostApi - functional programming interface
 * @export
 */
export const PostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostApiAxiosParamCreator(configuration)
    return {
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsPost(annotationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesPost(metaAnalysisPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsPost(resultInit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsPost(neurovaultCollection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesPost(neurovaultFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsPost(specificationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsPost(studysetPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostApi - factory interface
 * @export
 */
export const PostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostApiFp(configuration)
    return {
        /**
         * create a new serialized/referenced annotation
         * @summary Create a new Annotation
         * @param {AnnotationPostBody} [annotationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost(annotationPostBody?: AnnotationPostBody, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsPost(annotationPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new specification, studyset, annotation bundle
         * @summary Create a new meta-analysis
         * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ResultInit} [resultInit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsPost(resultInit?: ResultInit, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsPost(resultInit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create neurovault collection
         * @param {NeurovaultCollection} [neurovaultCollection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: any): AxiosPromise<void> {
            return localVarFp.neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {NeurovaultFile} [neurovaultFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesPost(neurovaultFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsPost(project, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost(specificationPostBody?: SpecificationPostBody, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsPost(specificationPostBody, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost(studysetPostBody?: StudysetPostBody, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsPost(studysetPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
export class PostApi extends BaseAPI {
    /**
     * create a new serialized/referenced annotation
     * @summary Create a new Annotation
     * @param {AnnotationPostBody} [annotationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public annotationsPost(annotationPostBody?: AnnotationPostBody, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).annotationsPost(annotationPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new specification, studyset, annotation bundle
     * @summary Create a new meta-analysis
     * @param {MetaAnalysisPostBody} [metaAnalysisPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public metaAnalysesPost(metaAnalysisPostBody?: MetaAnalysisPostBody, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).metaAnalysesPost(metaAnalysisPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ResultInit} [resultInit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public metaAnalysisResultsPost(resultInit?: ResultInit, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).metaAnalysisResultsPost(resultInit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create neurovault collection
     * @param {NeurovaultCollection} [neurovaultCollection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public neurovaultCollectionsPost(neurovaultCollection?: NeurovaultCollection, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).neurovaultCollectionsPost(neurovaultCollection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {NeurovaultFile} [neurovaultFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public neurovaultFilesPost(neurovaultFile?: NeurovaultFile, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).neurovaultFilesPost(neurovaultFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public projectsPost(project?: Project, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).projectsPost(project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new meta-analysis specification
     * @summary Create a Specification
     * @param {SpecificationPostBody} [specificationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).specificationsPost(specificationPostBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new serialized/referenced studyset
     * @summary Create a new Studyset
     * @param {StudysetPostBody} [studysetPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).studysetsPost(studysetPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet: async (page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdDelete', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet: async (id: string, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdGet', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut: async (id: string, project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPut', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost: async (project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdGet(id, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPut(id, project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsPost(project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsPost(project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Your GET endpoint
         * @param {number} [page] page of results
         * @param {number} [pageSize] number of elements to return on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [description] search description field for a term
         * @param {string} [sort] Parameter to sort results on
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {string} [userId] user id you want to filter on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: any): AxiosPromise<ProjectList> {
            return localVarFp.projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdGet(id: string, info?: boolean, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdGet(id, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut(id: string, project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdPut(id, project, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsPost(project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsPost(project, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Your GET endpoint
     * @param {number} [page] page of results
     * @param {number} [pageSize] number of elements to return on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [description] search description field for a term
     * @param {string} [sort] Parameter to sort results on
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {string} [userId] user id you want to filter on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsGet(page?: number, pageSize?: number, name?: string, search?: string, description?: string, sort?: string, desc?: boolean, userId?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsGet(page, pageSize, name, search, description, sort, desc, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [info] display additional information about a nested relationship without displaying fully nested object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsIdGet(id: string, info?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsIdGet(id, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsIdPut(id, project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsPost(project?: Project, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).projectsPost(project, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutApi - axios parameter creator
 * @export
 */
export const PutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut: async (id: string, annotationUpdate?: AnnotationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdPut', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut: async (id: string, metaAnalysis?: MetaAnalysis, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysesIdPut', 'id', id)
            const localVarPath = `/meta-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metaAnalysis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut: async (id: string, result?: Result, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('metaAnalysisResultsIdPut', 'id', id)
            const localVarPath = `/meta-analysis-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication upload_key required
            await setApiKeyToObject(localVarHeaderParameter, "Compose-Upload-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(result, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultCollectionsIdPut', 'id', id)
            const localVarPath = `/neurovault-collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut: async (id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('neurovaultFilesIdPut', 'id', id)
            const localVarPath = `/neurovault-files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (collectionId !== undefined) { 
                localVarFormParams.append('collection_id', collectionId as any);
            }
    
            if (exception !== undefined) { 
                localVarFormParams.append('exception', exception as any);
            }
    
            if (traceback !== undefined) { 
                localVarFormParams.append('traceback', traceback as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('status', status as any);
            }
    
            if (imageId !== undefined) { 
                localVarFormParams.append('image_id', imageId as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut: async (id: string, project?: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsIdPut', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut: async (id: string, specification?: Specification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdPut', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut: async (id: string, studyset?: Studyset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdPut', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutApi - functional programming interface
 * @export
 */
export const PutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutApiAxiosParamCreator(configuration)
    return {
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdPut(id, annotationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetaAnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysesIdPut(id, metaAnalysis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metaAnalysisResultsIdPut(id, result, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultCollectionsIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NeurovaultFileReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsIdPut(id, project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdPut(id, specification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdPut(id, studyset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutApi - factory interface
 * @export
 */
export const PutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutApiFp(configuration)
    return {
        /**
         * update an existing annotation
         * @summary Update an Annotation
         * @param {string} id 
         * @param {AnnotationUpdate} [annotationUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdPut(id, annotationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta-analysis (that has not yet been run)
         * @summary Update a meta-analysis
         * @param {string} id 
         * @param {MetaAnalysis} [metaAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: any): AxiosPromise<MetaAnalysisReturn> {
            return localVarFp.metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Result} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaAnalysisResultsIdPut(id: string, result?: Result, options?: any): AxiosPromise<ResultReturn> {
            return localVarFp.metaAnalysisResultsIdPut(id, result, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultCollectionsIdPut(id: string, options?: any): AxiosPromise<NeurovaultCollectionReturn> {
            return localVarFp.neurovaultCollectionsIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} [collectionId] 
         * @param {string} [exception] 
         * @param {string} [traceback] 
         * @param {string} [status] 
         * @param {string} [imageId] 
         * @param {string} [name] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: any): AxiosPromise<NeurovaultFileReturn> {
            return localVarFp.neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {Project} [project] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsIdPut(id: string, project?: Project, options?: any): AxiosPromise<ProjectReturn> {
            return localVarFp.projectsIdPut(id, project, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut(id: string, specification?: Specification, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdPut(id, specification, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut(id: string, studyset?: Studyset, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdPut(id, studyset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutApi - object-oriented interface
 * @export
 * @class PutApi
 * @extends {BaseAPI}
 */
export class PutApi extends BaseAPI {
    /**
     * update an existing annotation
     * @summary Update an Annotation
     * @param {string} id 
     * @param {AnnotationUpdate} [annotationUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public annotationsIdPut(id: string, annotationUpdate?: AnnotationUpdate, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).annotationsIdPut(id, annotationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta-analysis (that has not yet been run)
     * @summary Update a meta-analysis
     * @param {string} id 
     * @param {MetaAnalysis} [metaAnalysis] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public metaAnalysesIdPut(id: string, metaAnalysis?: MetaAnalysis, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).metaAnalysesIdPut(id, metaAnalysis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Result} [result] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public metaAnalysisResultsIdPut(id: string, result?: Result, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).metaAnalysisResultsIdPut(id, result, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public neurovaultCollectionsIdPut(id: string, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).neurovaultCollectionsIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} [collectionId] 
     * @param {string} [exception] 
     * @param {string} [traceback] 
     * @param {string} [status] 
     * @param {string} [imageId] 
     * @param {string} [name] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public neurovaultFilesIdPut(id: string, collectionId?: string, exception?: string, traceback?: string, status?: string, imageId?: string, name?: string, url?: string, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).neurovaultFilesIdPut(id, collectionId, exception, traceback, status, imageId, name, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {Project} [project] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public projectsIdPut(id: string, project?: Project, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).projectsIdPut(id, project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta analysis specification
     * @summary Update Meta-Analysis specification
     * @param {string} id 
     * @param {Specification} [specification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).specificationsIdPut(id, specification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing serialized/referenced studyset
     * @summary Update a Studyset
     * @param {string} id 
     * @param {Studyset} [studyset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutApi
     */
    public studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig) {
        return PutApiFp(this.configuration).studysetsIdPut(id, studyset, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecificationsApi - axios parameter creator
 * @export
 */
export const SpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdGet', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut: async (id: string, specification?: Specification, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('specificationsIdPut', 'id', id)
            const localVarPath = `/specifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost: async (specificationPostBody?: SpecificationPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(specificationPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecificationsApi - functional programming interface
 * @export
 */
export const SpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsIdPut(id, specification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecificationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.specificationsPost(specificationPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecificationsApi - factory interface
 * @export
 */
export const SpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecificationsApiFp(configuration)
    return {
        /**
         * list of meta-analysis specifications
         * @summary Get a list of Specifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsGet(options?: any): AxiosPromise<SpecificationList> {
            return localVarFp.specificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a meta-analysis specification
         * @summary Get information about a Specification
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdGet(id: string, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing meta analysis specification
         * @summary Update Meta-Analysis specification
         * @param {string} id 
         * @param {Specification} [specification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsIdPut(id: string, specification?: Specification, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsIdPut(id, specification, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new meta-analysis specification
         * @summary Create a Specification
         * @param {SpecificationPostBody} [specificationPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        specificationsPost(specificationPostBody?: SpecificationPostBody, options?: any): AxiosPromise<SpecificationReturn> {
            return localVarFp.specificationsPost(specificationPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecificationsApi - object-oriented interface
 * @export
 * @class SpecificationsApi
 * @extends {BaseAPI}
 */
export class SpecificationsApi extends BaseAPI {
    /**
     * list of meta-analysis specifications
     * @summary Get a list of Specifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationsApi
     */
    public specificationsGet(options?: AxiosRequestConfig) {
        return SpecificationsApiFp(this.configuration).specificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a meta-analysis specification
     * @summary Get information about a Specification
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationsApi
     */
    public specificationsIdGet(id: string, options?: AxiosRequestConfig) {
        return SpecificationsApiFp(this.configuration).specificationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing meta analysis specification
     * @summary Update Meta-Analysis specification
     * @param {string} id 
     * @param {Specification} [specification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationsApi
     */
    public specificationsIdPut(id: string, specification?: Specification, options?: AxiosRequestConfig) {
        return SpecificationsApiFp(this.configuration).specificationsIdPut(id, specification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new meta-analysis specification
     * @summary Create a Specification
     * @param {SpecificationPostBody} [specificationPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecificationsApi
     */
    public specificationsPost(specificationPostBody?: SpecificationPostBody, options?: AxiosRequestConfig) {
        return SpecificationsApiFp(this.configuration).specificationsPost(specificationPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudysetsApi - axios parameter creator
 * @export
 */
export const StudysetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdGet', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut: async (id: string, studyset?: Studyset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdPut', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost: async (studysetPostBody?: StudysetPostBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetPostBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudysetsApi - functional programming interface
 * @export
 */
export const StudysetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudysetsApiAxiosParamCreator(configuration)
    return {
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdPut(id, studyset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsPost(studysetPostBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudysetsApi - factory interface
 * @export
 */
export const StudysetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudysetsApiFp(configuration)
    return {
        /**
         * get a list of serialized/referenced studysets
         * @summary Get a list of Studysets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet(options?: any): AxiosPromise<StudysetList> {
            return localVarFp.studysetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get a single serialized/referenced studyset
         * @summary Get information about a Studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet(id: string, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an existing serialized/referenced studyset
         * @summary Update a Studyset
         * @param {string} id 
         * @param {Studyset} [studyset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut(id: string, studyset?: Studyset, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdPut(id, studyset, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new serialized/referenced studyset
         * @summary Create a new Studyset
         * @param {StudysetPostBody} [studysetPostBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost(studysetPostBody?: StudysetPostBody, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsPost(studysetPostBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudysetsApi - object-oriented interface
 * @export
 * @class StudysetsApi
 * @extends {BaseAPI}
 */
export class StudysetsApi extends BaseAPI {
    /**
     * get a list of serialized/referenced studysets
     * @summary Get a list of Studysets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsGet(options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get a single serialized/referenced studyset
     * @summary Get information about a Studyset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsIdGet(id: string, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an existing serialized/referenced studyset
     * @summary Update a Studyset
     * @param {string} id 
     * @param {Studyset} [studyset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsIdPut(id: string, studyset?: Studyset, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsIdPut(id, studyset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new serialized/referenced studyset
     * @summary Create a new Studyset
     * @param {StudysetPostBody} [studysetPostBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsPost(studysetPostBody?: StudysetPostBody, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsPost(studysetPostBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all users
         * @summary GET list of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the information of the user with the matching user ID.
         * @summary Get User Info by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update information about a user
         * @summary Update User Information
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut: async (id: string, user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPut', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a single user
         * @summary Create A New User
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * List all users
         * @summary GET list of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the information of the user with the matching user ID.
         * @summary Get User Info by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update information about a user
         * @summary Update User Information
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPut(id: string, user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a single user
         * @summary Create A New User
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * List all users
         * @summary GET list of Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(options?: any): AxiosPromise<UserList> {
            return localVarFp.usersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the information of the user with the matching user ID.
         * @summary Get User Info by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: string, options?: any): AxiosPromise<UserReturn> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update information about a user
         * @summary Update User Information
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, user?: User, options?: any): AxiosPromise<UserReturn> {
            return localVarFp.usersIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * create a single user
         * @summary Create A New User
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(user?: User, options?: any): AxiosPromise<UserReturn> {
            return localVarFp.usersPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * List all users
     * @summary GET list of Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the information of the user with the matching user ID.
     * @summary Get User Info by User ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update information about a user
     * @summary Update User Information
     * @param {string} id 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPut(id: string, user?: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a single user
     * @summary Create A New User
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(user?: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


