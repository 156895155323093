import { Style } from 'index';

const CurationImportCreateStub: Style = {
    textInput: {
        width: '100%',
        paddingBottom: '1rem',
    },
};

export default CurationImportCreateStub;
