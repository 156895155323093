/* tslint:disable */
/* eslint-disable */
/**
 * neurostore api
 * Create studysets for meta-analysis
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jamesdkent21@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnalysisBase
 */
export interface AnalysisBase {
    /**
     * A name of the contrast being performed.
     * @type {string}
     * @memberof AnalysisBase
     */
    'name'?: string | null;
    /**
     * A long form description of how the contrast was performed
     * @type {string}
     * @memberof AnalysisBase
     */
    'description'?: string | null;
    /**
     * Weight applied to each condition, must be the same length as the conditions attribute.
     * @type {Array<number>}
     * @memberof AnalysisBase
     */
    'weights'?: Array<number>;
}
/**
 * attributes common between request and return objects
 * @export
 * @interface AnalysisCommon
 */
export interface AnalysisCommon {
    /**
     * 
     * @type {string}
     * @memberof AnalysisCommon
     */
    'study'?: string;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof AnalysisCommon
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {number}
     * @memberof AnalysisCommon
     */
    'order'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof AnalysisCommon
     */
    'metadata'?: object | null;
}
/**
 * 
 * @export
 * @interface AnalysisList
 */
export interface AnalysisList {
    /**
     * 
     * @type {Metadata}
     * @memberof AnalysisList
     */
    'metadata'?: Metadata;
    /**
     * 
     * @type {Array<AnalysisReturn>}
     * @memberof AnalysisList
     */
    'results'?: Array<AnalysisReturn>;
}
/**
 * 
 * @export
 * @interface AnalysisRequest
 */
export interface AnalysisRequest {
    /**
     * A name of the contrast being performed.
     * @type {string}
     * @memberof AnalysisRequest
     */
    'name'?: string | null;
    /**
     * A long form description of how the contrast was performed
     * @type {string}
     * @memberof AnalysisRequest
     */
    'description'?: string | null;
    /**
     * Weight applied to each condition, must be the same length as the conditions attribute.
     * @type {Array<number>}
     * @memberof AnalysisRequest
     */
    'weights'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AnalysisRequest
     */
    'study'?: string;
    /**
     * 
     * @type {AnalysisRequestRelationshipsImages}
     * @memberof AnalysisRequest
     */
    'images'?: AnalysisRequestRelationshipsImages;
    /**
     * 
     * @type {AnalysisRequestRelationshipsPoints}
     * @memberof AnalysisRequest
     */
    'points'?: AnalysisRequestRelationshipsPoints;
    /**
     * 
     * @type {AnalysisRequestRelationshipsConditions}
     * @memberof AnalysisRequest
     */
    'conditions'?: AnalysisRequestRelationshipsConditions;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof AnalysisRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof AnalysisRequest
     */
    'public'?: boolean;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof AnalysisRequest
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {number}
     * @memberof AnalysisRequest
     */
    'order'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof AnalysisRequest
     */
    'metadata'?: object | null;
}
/**
 * 
 * @export
 * @interface AnalysisRequestRelationships
 */
export interface AnalysisRequestRelationships {
    /**
     * 
     * @type {string}
     * @memberof AnalysisRequestRelationships
     */
    'study'?: string;
    /**
     * 
     * @type {AnalysisRequestRelationshipsImages}
     * @memberof AnalysisRequestRelationships
     */
    'images'?: AnalysisRequestRelationshipsImages;
    /**
     * 
     * @type {AnalysisRequestRelationshipsPoints}
     * @memberof AnalysisRequestRelationships
     */
    'points'?: AnalysisRequestRelationshipsPoints;
    /**
     * 
     * @type {AnalysisRequestRelationshipsConditions}
     * @memberof AnalysisRequestRelationships
     */
    'conditions'?: AnalysisRequestRelationshipsConditions;
}
/**
 * @type AnalysisRequestRelationshipsConditions
 * @export
 */
export type AnalysisRequestRelationshipsConditions = Array<ConditionRequest> | Array<string>;

/**
 * @type AnalysisRequestRelationshipsImages
 * @export
 */
export type AnalysisRequestRelationshipsImages = Array<ImageRequest> | Array<string>;

/**
 * @type AnalysisRequestRelationshipsPoints
 * @export
 */
export type AnalysisRequestRelationshipsPoints = Array<PointRequest> | Array<string>;

/**
 * 
 * @export
 * @interface AnalysisReturn
 */
export interface AnalysisReturn {
    /**
     * A name of the contrast being performed.
     * @type {string}
     * @memberof AnalysisReturn
     */
    'name'?: string | null;
    /**
     * A long form description of how the contrast was performed
     * @type {string}
     * @memberof AnalysisReturn
     */
    'description'?: string | null;
    /**
     * Weight applied to each condition, must be the same length as the conditions attribute.
     * @type {Array<number>}
     * @memberof AnalysisReturn
     */
    'weights'?: Array<number>;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof AnalysisReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof AnalysisReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof AnalysisReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof AnalysisReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof AnalysisReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof AnalysisReturn
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisReturn
     */
    'study'?: string;
    /**
     * 
     * @type {AnalysisReturnRelationshipsImages}
     * @memberof AnalysisReturn
     */
    'images'?: AnalysisReturnRelationshipsImages;
    /**
     * 
     * @type {AnalysisReturnRelationshipsPoints}
     * @memberof AnalysisReturn
     */
    'points'?: AnalysisReturnRelationshipsPoints;
    /**
     * 
     * @type {AnalysisReturnRelationshipsConditions}
     * @memberof AnalysisReturn
     */
    'conditions'?: AnalysisReturnRelationshipsConditions;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof AnalysisReturn
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {number}
     * @memberof AnalysisReturn
     */
    'order'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof AnalysisReturn
     */
    'metadata'?: object | null;
}
/**
 * 
 * @export
 * @interface AnalysisReturnRelationships
 */
export interface AnalysisReturnRelationships {
    /**
     * 
     * @type {string}
     * @memberof AnalysisReturnRelationships
     */
    'study'?: string;
    /**
     * 
     * @type {AnalysisReturnRelationshipsImages}
     * @memberof AnalysisReturnRelationships
     */
    'images'?: AnalysisReturnRelationshipsImages;
    /**
     * 
     * @type {AnalysisReturnRelationshipsPoints}
     * @memberof AnalysisReturnRelationships
     */
    'points'?: AnalysisReturnRelationshipsPoints;
    /**
     * 
     * @type {AnalysisReturnRelationshipsConditions}
     * @memberof AnalysisReturnRelationships
     */
    'conditions'?: AnalysisReturnRelationshipsConditions;
}
/**
 * @type AnalysisReturnRelationshipsConditions
 * @export
 */
export type AnalysisReturnRelationshipsConditions = Array<ConditionReturn> | Array<string>;

/**
 * @type AnalysisReturnRelationshipsImages
 * @export
 */
export type AnalysisReturnRelationshipsImages = Array<ImageReturn> | Array<string>;

/**
 * @type AnalysisReturnRelationshipsPoints
 * @export
 */
export type AnalysisReturnRelationshipsPoints = Array<PointReturn> | Array<string>;

/**
 * 
 * @export
 * @interface AnnotationBase
 */
export interface AnnotationBase {
    /**
     * Descriptive name for the annotation.
     * @type {string}
     * @memberof AnnotationBase
     */
    'name'?: string | null;
    /**
     * Long form description of the annotation.
     * @type {string}
     * @memberof AnnotationBase
     */
    'description'?: string | null;
    /**
     * object describing metadata about the annotation, such as software used or descriptions of the keys used in the annotation.
     * @type {object}
     * @memberof AnnotationBase
     */
    'metadata'?: object | null;
    /**
     * The keys (columns) in the annotation and the key\'s respective data type (such as an integer or string).
     * @type {object}
     * @memberof AnnotationBase
     */
    'note_keys'?: object | null;
}
/**
 * 
 * @export
 * @interface AnnotationCommon
 */
export interface AnnotationCommon {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCommon
     */
    'studyset'?: string;
}
/**
 * exporting an annotation as a CSV for easier editing
 * @export
 * @interface AnnotationExport
 */
export interface AnnotationExport {
    /**
     * 
     * @type {object}
     * @memberof AnnotationExport
     */
    'metadata'?: object | null;
    /**
     * annotation object expressed as a CSV
     * @type {string}
     * @memberof AnnotationExport
     */
    'annotation_csv': string;
}
/**
 * 
 * @export
 * @interface AnnotationList
 */
export interface AnnotationList {
    /**
     * 
     * @type {Array<AnnotationReturn>}
     * @memberof AnnotationList
     */
    'results'?: Array<AnnotationReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof AnnotationList
     */
    'metadata'?: Metadata;
}
/**
 * @type AnnotationRequest
 * @export
 */
export type AnnotationRequest = AnnotationRequestOneOf | AnnotationReturnOneOf;

/**
 * 
 * @export
 * @interface AnnotationRequestOneOf
 */
export interface AnnotationRequestOneOf {
    /**
     * Descriptive name for the annotation.
     * @type {string}
     * @memberof AnnotationRequestOneOf
     */
    'name'?: string | null;
    /**
     * Long form description of the annotation.
     * @type {string}
     * @memberof AnnotationRequestOneOf
     */
    'description'?: string | null;
    /**
     * object describing metadata about the annotation, such as software used or descriptions of the keys used in the annotation.
     * @type {object}
     * @memberof AnnotationRequestOneOf
     */
    'metadata'?: object | null;
    /**
     * The keys (columns) in the annotation and the key\'s respective data type (such as an integer or string).
     * @type {object}
     * @memberof AnnotationRequestOneOf
     */
    'note_keys'?: object | null;
    /**
     * 
     * @type {AnnotationRequestRelationshipsNotes}
     * @memberof AnnotationRequestOneOf
     */
    'notes'?: AnnotationRequestRelationshipsNotes;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof AnnotationRequestOneOf
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof AnnotationRequestOneOf
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnotationRequestOneOf
     */
    'studyset'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationRequestRelationships
 */
export interface AnnotationRequestRelationships {
    /**
     * 
     * @type {AnnotationRequestRelationshipsNotes}
     * @memberof AnnotationRequestRelationships
     */
    'notes'?: AnnotationRequestRelationshipsNotes;
}
/**
 * @type AnnotationRequestRelationshipsNotes
 * @export
 */
export type AnnotationRequestRelationshipsNotes = Array<NoteCollectionRequest> | Array<string>;

/**
 * @type AnnotationReturn
 * @export
 */
export type AnnotationReturn = AnnotationReturnOneOf | AnnotationReturnOneOf1;

/**
 * 
 * @export
 * @interface AnnotationReturnOneOf
 */
export interface AnnotationReturnOneOf {
    /**
     * 
     * @type {object}
     * @memberof AnnotationReturnOneOf
     */
    'metadata'?: object | null;
    /**
     * annotation object expressed as a CSV
     * @type {string}
     * @memberof AnnotationReturnOneOf
     */
    'annotation_csv': string;
}
/**
 * 
 * @export
 * @interface AnnotationReturnOneOf1
 */
export interface AnnotationReturnOneOf1 {
    /**
     * Descriptive name for the annotation.
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'name'?: string | null;
    /**
     * Long form description of the annotation.
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'description'?: string | null;
    /**
     * object describing metadata about the annotation, such as software used or descriptions of the keys used in the annotation.
     * @type {object}
     * @memberof AnnotationReturnOneOf1
     */
    'metadata'?: object | null;
    /**
     * The keys (columns) in the annotation and the key\'s respective data type (such as an integer or string).
     * @type {object}
     * @memberof AnnotationReturnOneOf1
     */
    'note_keys'?: object | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof AnnotationReturnOneOf1
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'source_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'source_updated_at'?: string | null;
    /**
     * 
     * @type {AnnotationReturnRelationshipsNotes}
     * @memberof AnnotationReturnOneOf1
     */
    'notes'?: AnnotationReturnRelationshipsNotes;
    /**
     * 
     * @type {string}
     * @memberof AnnotationReturnOneOf1
     */
    'studyset'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationReturnRelationships
 */
export interface AnnotationReturnRelationships {
    /**
     * 
     * @type {AnnotationReturnRelationshipsNotes}
     * @memberof AnnotationReturnRelationships
     */
    'notes'?: AnnotationReturnRelationshipsNotes;
}
/**
 * @type AnnotationReturnRelationshipsNotes
 * @export
 */
export type AnnotationReturnRelationshipsNotes = Array<NoteCollectionReturn> | Array<string>;

/**
 * @type BaseStudiesPost200Response
 * @export
 */
export type BaseStudiesPost200Response = Array<BaseStudyReturn> | BaseStudyReturn;

/**
 * @type BaseStudiesPostRequest
 * @export
 */
export type BaseStudiesPostRequest = Array<BaseStudy> | BaseStudy;

/**
 * 
 * @export
 * @interface BaseStudy
 */
export interface BaseStudy {
    /**
     * 
     * @type {object}
     * @memberof BaseStudy
     */
    'metadata'?: object | null;
    /**
     * 
     * @type {BaseStudyVersions}
     * @memberof BaseStudy
     */
    'versions'?: BaseStudyVersions;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'publication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'pmid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'authors'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseStudy
     */
    'year'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudy
     */
    'pmcid'?: string | null;
}
/**
 * 
 * @export
 * @interface BaseStudyList
 */
export interface BaseStudyList {
    /**
     * 
     * @type {Array<BaseStudyReturn>}
     * @memberof BaseStudyList
     */
    'results'?: Array<BaseStudyReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof BaseStudyList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface BaseStudyReturn
 */
export interface BaseStudyReturn {
    /**
     * 
     * @type {object}
     * @memberof BaseStudyReturn
     */
    'metadata'?: object | null;
    /**
     * 
     * @type {BaseStudyVersions}
     * @memberof BaseStudyReturn
     */
    'versions'?: BaseStudyVersions;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'publication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'doi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'pmid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'authors'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseStudyReturn
     */
    'year'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'pmcid'?: string | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof BaseStudyReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof BaseStudyReturn
     */
    'username'?: string | null;
}
/**
 * @type BaseStudyVersions
 * @export
 */
export type BaseStudyVersions = Array<StudyReturn> | Array<string>;

/**
 * 
 * @export
 * @interface Clone
 */
export interface Clone {
    /**
     * 
     * @type {string}
     * @memberof Clone
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Clone
     */
    'source_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Clone
     */
    'source_updated_at'?: string | null;
}
/**
 * 
 * @export
 * @interface ConditionBase
 */
export interface ConditionBase {
    /**
     * Name of the condition being applied in the contrast, either psychological, pharmacological, or group based.
     * @type {string}
     * @memberof ConditionBase
     */
    'name'?: string | null;
    /**
     * Long form description of how the condition is operationalized and/or specific meaning.
     * @type {string}
     * @memberof ConditionBase
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ConditionList
 */
export interface ConditionList {
    /**
     * 
     * @type {Array<ConditionReturn>}
     * @memberof ConditionList
     */
    'results'?: Array<ConditionReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof ConditionList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface ConditionRequest
 */
export interface ConditionRequest {
    /**
     * Name of the condition being applied in the contrast, either psychological, pharmacological, or group based.
     * @type {string}
     * @memberof ConditionRequest
     */
    'name'?: string | null;
    /**
     * Long form description of how the condition is operationalized and/or specific meaning.
     * @type {string}
     * @memberof ConditionRequest
     */
    'description'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof ConditionRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof ConditionRequest
     */
    'public'?: boolean;
}
/**
 * 
 * @export
 * @interface ConditionReturn
 */
export interface ConditionReturn {
    /**
     * Name of the condition being applied in the contrast, either psychological, pharmacological, or group based.
     * @type {string}
     * @memberof ConditionReturn
     */
    'name'?: string | null;
    /**
     * Long form description of how the condition is operationalized and/or specific meaning.
     * @type {string}
     * @memberof ConditionReturn
     */
    'description'?: string | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof ConditionReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof ConditionReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof ConditionReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof ConditionReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof ConditionReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof ConditionReturn
     */
    'username'?: string | null;
}
/**
 * WARNING: not currently used. descriptor of level of analysis for a particular image/point (run, session, subject, group, meta)
 * @export
 * @interface Entity
 */
export interface Entity {
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'analysis'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageBase
 */
export interface ImageBase {
    /**
     * Metadata about image such as software and version used and other relevant data about how the image was produced.
     * @type {object}
     * @memberof ImageBase
     */
    'metadata'?: object | null;
    /**
     * URL to image file.
     * @type {string}
     * @memberof ImageBase
     */
    'url'?: string | null;
    /**
     * Name of the image file.
     * @type {string}
     * @memberof ImageBase
     */
    'filename'?: string | null;
    /**
     * The template space the image is in (e.g., MNI 
     * @type {string}
     * @memberof ImageBase
     */
    'space'?: string | null;
    /**
     * The values the image represents. For example, T-statistic or Z-statistic, or Betas.
     * @type {string}
     * @memberof ImageBase
     */
    'value_type'?: string | null;
    /**
     * Date the image was added.
     * @type {string}
     * @memberof ImageBase
     */
    'add_date'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageCommon
 */
export interface ImageCommon {
    /**
     * 
     * @type {string}
     * @memberof ImageCommon
     */
    'analysis'?: string;
}
/**
 * 
 * @export
 * @interface ImageList
 */
export interface ImageList {
    /**
     * 
     * @type {Array<ImageReturn>}
     * @memberof ImageList
     */
    'results'?: Array<ImageReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof ImageList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface ImageRelationships
 */
export interface ImageRelationships {
    /**
     * 
     * @type {string}
     * @memberof ImageRelationships
     */
    'analysis'?: string;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof ImageRelationships
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {string}
     * @memberof ImageRelationships
     */
    'analysis_name'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageRequest
 */
export interface ImageRequest {
    /**
     * Metadata about image such as software and version used and other relevant data about how the image was produced.
     * @type {object}
     * @memberof ImageRequest
     */
    'metadata'?: object | null;
    /**
     * URL to image file.
     * @type {string}
     * @memberof ImageRequest
     */
    'url'?: string | null;
    /**
     * Name of the image file.
     * @type {string}
     * @memberof ImageRequest
     */
    'filename'?: string | null;
    /**
     * The template space the image is in (e.g., MNI 
     * @type {string}
     * @memberof ImageRequest
     */
    'space'?: string | null;
    /**
     * The values the image represents. For example, T-statistic or Z-statistic, or Betas.
     * @type {string}
     * @memberof ImageRequest
     */
    'value_type'?: string | null;
    /**
     * Date the image was added.
     * @type {string}
     * @memberof ImageRequest
     */
    'add_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'analysis'?: string;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof ImageRequest
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {string}
     * @memberof ImageRequest
     */
    'analysis_name'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof ImageRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof ImageRequest
     */
    'public'?: boolean;
}
/**
 * 
 * @export
 * @interface ImageReturn
 */
export interface ImageReturn {
    /**
     * Metadata about image such as software and version used and other relevant data about how the image was produced.
     * @type {object}
     * @memberof ImageReturn
     */
    'metadata'?: object | null;
    /**
     * URL to image file.
     * @type {string}
     * @memberof ImageReturn
     */
    'url'?: string | null;
    /**
     * Name of the image file.
     * @type {string}
     * @memberof ImageReturn
     */
    'filename'?: string | null;
    /**
     * The template space the image is in (e.g., MNI 
     * @type {string}
     * @memberof ImageReturn
     */
    'space'?: string | null;
    /**
     * The values the image represents. For example, T-statistic or Z-statistic, or Betas.
     * @type {string}
     * @memberof ImageReturn
     */
    'value_type'?: string | null;
    /**
     * Date the image was added.
     * @type {string}
     * @memberof ImageReturn
     */
    'add_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageReturn
     */
    'analysis'?: string;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof ImageReturn
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {string}
     * @memberof ImageReturn
     */
    'analysis_name'?: string | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof ImageReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof ImageReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof ImageReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof ImageReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof ImageReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof ImageReturn
     */
    'username'?: string | null;
}
/**
 * JSON-LD elements for data tracking
 * @export
 * @interface JsonLd
 */
export interface JsonLd {
    /**
     * 
     * @type {JsonLdContext}
     * @memberof JsonLd
     */
    '@context'?: JsonLdContext;
    /**
     * URI of the resource
     * @type {string}
     * @memberof JsonLd
     */
    '@id'?: string;
    /**
     * One of the NiMADS data types
     * @type {string}
     * @memberof JsonLd
     */
    '@type'?: string;
}
/**
 * Context for the shorthand names
 * @export
 * @interface JsonLdContext
 */
export interface JsonLdContext {
    /**
     * common URI prefix for @type
     * @type {string}
     * @memberof JsonLdContext
     */
    '@vocab'?: string;
}
/**
 * data included in every list response
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * total number of entries 
     * @type {number}
     * @memberof Metadata
     */
    'total_count'?: number;
    /**
     * count of elements for unique entries
     * @type {number}
     * @memberof Metadata
     */
    'unique_count'?: number;
}
/**
 * 
 * @export
 * @interface NestedPutAttributes
 */
export interface NestedPutAttributes {
    /**
     * 
     * @type {string}
     * @memberof NestedPutAttributes
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface NoteCollectionBase
 */
export interface NoteCollectionBase {
    /**
     * The note will contain all note_keys as keys and have a value of either null or the value type specified in note_keys.
     * @type {object}
     * @memberof NoteCollectionBase
     */
    'note'?: object | null;
}
/**
 * 
 * @export
 * @interface NoteCollectionList
 */
export interface NoteCollectionList {
    /**
     * 
     * @type {Array<NoteCollectionReturn>}
     * @memberof NoteCollectionList
     */
    'results'?: Array<NoteCollectionReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof NoteCollectionList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface NoteCollectionRequest
 */
export interface NoteCollectionRequest {
    /**
     * The note will contain all note_keys as keys and have a value of either null or the value type specified in note_keys.
     * @type {object}
     * @memberof NoteCollectionRequest
     */
    'note'?: object | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof NoteCollectionRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof NoteCollectionRequest
     */
    'public'?: boolean;
}
/**
 * 
 * @export
 * @interface NoteCollectionReturn
 */
export interface NoteCollectionReturn {
    /**
     * The note will contain all note_keys as keys and have a value of either null or the value type specified in note_keys.
     * @type {object}
     * @memberof NoteCollectionReturn
     */
    'note'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'analysis'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'analysis_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'study'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'study_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'annotation'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteCollectionReturn
     */
    'study_year'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'publication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'authors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturn
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface NoteCollectionReturnAllOf
 */
export interface NoteCollectionReturnAllOf {
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'analysis'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'analysis_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'study'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'study_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'annotation'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteCollectionReturnAllOf
     */
    'study_year'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'publication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'authors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteCollectionReturnAllOf
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface PointBase
 */
export interface PointBase {
    /**
     * Location of the significant coordinate in three dimensional space.
     * @type {Array<number>}
     * @memberof PointBase
     */
    'coordinates'?: Array<number>;
    /**
     * Template space used to determine coordinate Examples include TAL or MNI.
     * @type {string}
     * @memberof PointBase
     */
    'space'?: string | null;
    /**
     * Method of how point was derived (e.g., center of mass)
     * @type {string}
     * @memberof PointBase
     */
    'kind'?: string | null;
    /**
     * If the point is associated with an image, this is the value the point takes in that image.
     * @type {string}
     * @memberof PointBase
     */
    'label_id'?: string | null;
}
/**
 * 
 * @export
 * @interface PointCommon
 */
export interface PointCommon {
    /**
     * 
     * @type {string}
     * @memberof PointCommon
     */
    'analysis'?: string;
    /**
     * size of the cluster in cubic millimeters
     * @type {number}
     * @memberof PointCommon
     */
    'cluster_size'?: number | null;
    /**
     * whether the reported peak is the max-peak statistic or a sub-maxmimal peak.
     * @type {boolean}
     * @memberof PointCommon
     */
    'subpeak'?: boolean | null;
    /**
     * determines the row to display the coordinate
     * @type {number}
     * @memberof PointCommon
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface PointList
 */
export interface PointList {
    /**
     * 
     * @type {Array<PointReturn>}
     * @memberof PointList
     */
    'results'?: Array<PointReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof PointList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface PointRelationships
 */
export interface PointRelationships {
    /**
     * 
     * @type {string}
     * @memberof PointRelationships
     */
    'image'?: string | null;
    /**
     * 
     * @type {PointRelationshipsValues}
     * @memberof PointRelationships
     */
    'values'?: PointRelationshipsValues;
    /**
     * 
     * @type {number}
     * @memberof PointRelationships
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRelationships
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRelationships
     */
    'z'?: number;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof PointRelationships
     */
    'entities'?: Array<Entity>;
}
/**
 * @type PointRelationshipsValues
 * @export
 */
export type PointRelationshipsValues = Array<PointValue> | Array<string>;

/**
 * 
 * @export
 * @interface PointRequest
 */
export interface PointRequest {
    /**
     * Location of the significant coordinate in three dimensional space.
     * @type {Array<number>}
     * @memberof PointRequest
     */
    'coordinates'?: Array<number>;
    /**
     * Template space used to determine coordinate Examples include TAL or MNI.
     * @type {string}
     * @memberof PointRequest
     */
    'space'?: string | null;
    /**
     * Method of how point was derived (e.g., center of mass)
     * @type {string}
     * @memberof PointRequest
     */
    'kind'?: string | null;
    /**
     * If the point is associated with an image, this is the value the point takes in that image.
     * @type {string}
     * @memberof PointRequest
     */
    'label_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PointRequest
     */
    'image'?: string | null;
    /**
     * 
     * @type {PointRelationshipsValues}
     * @memberof PointRequest
     */
    'values'?: PointRelationshipsValues;
    /**
     * 
     * @type {number}
     * @memberof PointRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRequest
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRequest
     */
    'z'?: number;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof PointRequest
     */
    'entities'?: Array<Entity>;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof PointRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof PointRequest
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PointRequest
     */
    'analysis'?: string;
    /**
     * size of the cluster in cubic millimeters
     * @type {number}
     * @memberof PointRequest
     */
    'cluster_size'?: number | null;
    /**
     * whether the reported peak is the max-peak statistic or a sub-maxmimal peak.
     * @type {boolean}
     * @memberof PointRequest
     */
    'subpeak'?: boolean | null;
    /**
     * determines the row to display the coordinate
     * @type {number}
     * @memberof PointRequest
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface PointReturn
 */
export interface PointReturn {
    /**
     * Location of the significant coordinate in three dimensional space.
     * @type {Array<number>}
     * @memberof PointReturn
     */
    'coordinates'?: Array<number>;
    /**
     * Template space used to determine coordinate Examples include TAL or MNI.
     * @type {string}
     * @memberof PointReturn
     */
    'space'?: string | null;
    /**
     * Method of how point was derived (e.g., center of mass)
     * @type {string}
     * @memberof PointReturn
     */
    'kind'?: string | null;
    /**
     * If the point is associated with an image, this is the value the point takes in that image.
     * @type {string}
     * @memberof PointReturn
     */
    'label_id'?: string | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof PointReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof PointReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof PointReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof PointReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof PointReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof PointReturn
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PointReturn
     */
    'image'?: string | null;
    /**
     * 
     * @type {PointRelationshipsValues}
     * @memberof PointReturn
     */
    'values'?: PointRelationshipsValues;
    /**
     * 
     * @type {number}
     * @memberof PointReturn
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointReturn
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointReturn
     */
    'z'?: number;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof PointReturn
     */
    'entities'?: Array<Entity>;
    /**
     * 
     * @type {string}
     * @memberof PointReturn
     */
    'analysis'?: string;
    /**
     * size of the cluster in cubic millimeters
     * @type {number}
     * @memberof PointReturn
     */
    'cluster_size'?: number | null;
    /**
     * whether the reported peak is the max-peak statistic or a sub-maxmimal peak.
     * @type {boolean}
     * @memberof PointReturn
     */
    'subpeak'?: boolean | null;
    /**
     * determines the row to display the coordinate
     * @type {number}
     * @memberof PointReturn
     */
    'order'?: number | null;
}
/**
 * 
 * @export
 * @interface PointValue
 */
export interface PointValue {
    /**
     * 
     * @type {string}
     * @memberof PointValue
     */
    'kind'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PointValue
     */
    'value'?: number | null;
}
/**
 * common readable resource attributes
 * @export
 * @interface ReadableResourceAttributes
 */
export interface ReadableResourceAttributes {
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof ReadableResourceAttributes
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof ReadableResourceAttributes
     */
    'updated_at'?: string | null;
}
/**
 * common attributes for user owned resources
 * @export
 * @interface ResourceAttributes
 */
export interface ResourceAttributes {
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof ResourceAttributes
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof ResourceAttributes
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof ResourceAttributes
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof ResourceAttributes
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof ResourceAttributes
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof ResourceAttributes
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface StudyBase
 */
export interface StudyBase {
    /**
     * Digital object identifier of the study.
     * @type {string}
     * @memberof StudyBase
     */
    'doi'?: string | null;
    /**
     * Title of the study.
     * @type {string}
     * @memberof StudyBase
     */
    'name'?: string | null;
    /**
     * Metadata associated with the study not covered by the other study attributes.
     * @type {object}
     * @memberof StudyBase
     */
    'metadata'?: object | null;
    /**
     * Long form description of the study, typically the abstract.
     * @type {string}
     * @memberof StudyBase
     */
    'description'?: string | null;
    /**
     * The journal/place of publication for the study.
     * @type {string}
     * @memberof StudyBase
     */
    'publication'?: string | null;
    /**
     * If the study was published on PubMed, place the PubMed ID here.
     * @type {string}
     * @memberof StudyBase
     */
    'pmid'?: string | null;
    /**
     * The authors on the publication of this study.
     * @type {string}
     * @memberof StudyBase
     */
    'authors'?: string | null;
    /**
     * The year this study was published.
     * @type {number}
     * @memberof StudyBase
     */
    'year'?: number | null;
}
/**
 * 
 * @export
 * @interface StudyCommon
 */
export interface StudyCommon {
    /**
     * 
     * @type {string}
     * @memberof StudyCommon
     */
    'level'?: StudyCommonLevelEnum;
}

export const StudyCommonLevelEnum = {
    Group: 'group',
    Meta: 'meta'
} as const;

export type StudyCommonLevelEnum = typeof StudyCommonLevelEnum[keyof typeof StudyCommonLevelEnum];

/**
 * 
 * @export
 * @interface StudyList
 */
export interface StudyList {
    /**
     * 
     * @type {Array<StudyReturn>}
     * @memberof StudyList
     */
    'results'?: Array<StudyReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof StudyList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface StudyRequest
 */
export interface StudyRequest {
    /**
     * Digital object identifier of the study.
     * @type {string}
     * @memberof StudyRequest
     */
    'doi'?: string | null;
    /**
     * Title of the study.
     * @type {string}
     * @memberof StudyRequest
     */
    'name'?: string | null;
    /**
     * Metadata associated with the study not covered by the other study attributes.
     * @type {object}
     * @memberof StudyRequest
     */
    'metadata'?: object | null;
    /**
     * Long form description of the study, typically the abstract.
     * @type {string}
     * @memberof StudyRequest
     */
    'description'?: string | null;
    /**
     * The journal/place of publication for the study.
     * @type {string}
     * @memberof StudyRequest
     */
    'publication'?: string | null;
    /**
     * If the study was published on PubMed, place the PubMed ID here.
     * @type {string}
     * @memberof StudyRequest
     */
    'pmid'?: string | null;
    /**
     * The authors on the publication of this study.
     * @type {string}
     * @memberof StudyRequest
     */
    'authors'?: string | null;
    /**
     * The year this study was published.
     * @type {number}
     * @memberof StudyRequest
     */
    'year'?: number | null;
    /**
     * 
     * @type {StudyRequestRelationshipsAnalyses}
     * @memberof StudyRequest
     */
    'analyses'?: StudyRequestRelationshipsAnalyses;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof StudyRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof StudyRequest
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudyRequest
     */
    'pmcid'?: string | null;
}
/**
 * 
 * @export
 * @interface StudyRequestAllOf
 */
export interface StudyRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof StudyRequestAllOf
     */
    'pmcid'?: string | null;
}
/**
 * 
 * @export
 * @interface StudyRequestRelationships
 */
export interface StudyRequestRelationships {
    /**
     * 
     * @type {StudyRequestRelationshipsAnalyses}
     * @memberof StudyRequestRelationships
     */
    'analyses'?: StudyRequestRelationshipsAnalyses;
}
/**
 * @type StudyRequestRelationshipsAnalyses
 * @export
 */
export type StudyRequestRelationshipsAnalyses = Array<AnalysisRequest> | Array<string>;

/**
 * 
 * @export
 * @interface StudyReturn
 */
export interface StudyReturn {
    /**
     * Digital object identifier of the study.
     * @type {string}
     * @memberof StudyReturn
     */
    'doi'?: string | null;
    /**
     * Title of the study.
     * @type {string}
     * @memberof StudyReturn
     */
    'name'?: string | null;
    /**
     * Metadata associated with the study not covered by the other study attributes.
     * @type {object}
     * @memberof StudyReturn
     */
    'metadata'?: object | null;
    /**
     * Long form description of the study, typically the abstract.
     * @type {string}
     * @memberof StudyReturn
     */
    'description'?: string | null;
    /**
     * The journal/place of publication for the study.
     * @type {string}
     * @memberof StudyReturn
     */
    'publication'?: string | null;
    /**
     * If the study was published on PubMed, place the PubMed ID here.
     * @type {string}
     * @memberof StudyReturn
     */
    'pmid'?: string | null;
    /**
     * The authors on the publication of this study.
     * @type {string}
     * @memberof StudyReturn
     */
    'authors'?: string | null;
    /**
     * The year this study was published.
     * @type {number}
     * @memberof StudyReturn
     */
    'year'?: number | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof StudyReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof StudyReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof StudyReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof StudyReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof StudyReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof StudyReturn
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturn
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturn
     */
    'source_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturn
     */
    'source_updated_at'?: string | null;
    /**
     * 
     * @type {StudyReturnRelationshipsAnalyses}
     * @memberof StudyReturn
     */
    'analyses'?: StudyReturnRelationshipsAnalyses;
    /**
     * 
     * @type {Array<StudyReturnAllOfStudysetsInner>}
     * @memberof StudyReturn
     */
    'studysets'?: Array<StudyReturnAllOfStudysetsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof StudyReturn
     */
    'has_coordinates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudyReturn
     */
    'has_images'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudyReturn
     */
    'base_study'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturn
     */
    'pmcid'?: string | null;
}
/**
 * 
 * @export
 * @interface StudyReturnAllOf
 */
export interface StudyReturnAllOf {
    /**
     * 
     * @type {Array<StudyReturnAllOfStudysetsInner>}
     * @memberof StudyReturnAllOf
     */
    'studysets'?: Array<StudyReturnAllOfStudysetsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof StudyReturnAllOf
     */
    'has_coordinates'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudyReturnAllOf
     */
    'has_images'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudyReturnAllOf
     */
    'base_study'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturnAllOf
     */
    'pmcid'?: string | null;
}
/**
 * @type StudyReturnAllOfStudysetsInner
 * @export
 */
export type StudyReturnAllOfStudysetsInner = StudyReturnAllOfStudysetsInnerOneOf | string;

/**
 * 
 * @export
 * @interface StudyReturnAllOfStudysetsInnerOneOf
 */
export interface StudyReturnAllOfStudysetsInnerOneOf {
    /**
     * 
     * @type {string}
     * @memberof StudyReturnAllOfStudysetsInnerOneOf
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudyReturnAllOfStudysetsInnerOneOf
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyReturnAllOfStudysetsInnerOneOf
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface StudyReturnRelationships
 */
export interface StudyReturnRelationships {
    /**
     * 
     * @type {StudyReturnRelationshipsAnalyses}
     * @memberof StudyReturnRelationships
     */
    'analyses'?: StudyReturnRelationshipsAnalyses;
}
/**
 * @type StudyReturnRelationshipsAnalyses
 * @export
 */
export type StudyReturnRelationshipsAnalyses = Array<AnalysisReturn> | Array<string>;

/**
 * 
 * @export
 * @interface StudysetBase
 */
export interface StudysetBase {
    /**
     * Descriptive and human readable name of the studyset.
     * @type {string}
     * @memberof StudysetBase
     */
    'name'?: string | null;
    /**
     * A longform description of the studyset.
     * @type {string}
     * @memberof StudysetBase
     */
    'description'?: string | null;
    /**
     * The journal/source the studyset is connected to if the studyset was published.
     * @type {string}
     * @memberof StudysetBase
     */
    'publication'?: string | null;
    /**
     * A DOI connected to the published studyset (may change to being automatically created so each studyset connected to a successful meta-analysis gets a DOI).
     * @type {string}
     * @memberof StudysetBase
     */
    'doi'?: string | null;
    /**
     * If the article connected to the studyset was published on PubMed, then link the ID here.
     * @type {string}
     * @memberof StudysetBase
     */
    'pmid'?: string | null;
}
/**
 * 
 * @export
 * @interface StudysetList
 */
export interface StudysetList {
    /**
     * 
     * @type {Array<StudysetReturn>}
     * @memberof StudysetList
     */
    'results'?: Array<StudysetReturn>;
    /**
     * 
     * @type {Metadata}
     * @memberof StudysetList
     */
    'metadata'?: Metadata;
}
/**
 * 
 * @export
 * @interface StudysetRequest
 */
export interface StudysetRequest {
    /**
     * Descriptive and human readable name of the studyset.
     * @type {string}
     * @memberof StudysetRequest
     */
    'name'?: string | null;
    /**
     * A longform description of the studyset.
     * @type {string}
     * @memberof StudysetRequest
     */
    'description'?: string | null;
    /**
     * The journal/source the studyset is connected to if the studyset was published.
     * @type {string}
     * @memberof StudysetRequest
     */
    'publication'?: string | null;
    /**
     * A DOI connected to the published studyset (may change to being automatically created so each studyset connected to a successful meta-analysis gets a DOI).
     * @type {string}
     * @memberof StudysetRequest
     */
    'doi'?: string | null;
    /**
     * If the article connected to the studyset was published on PubMed, then link the ID here.
     * @type {string}
     * @memberof StudysetRequest
     */
    'pmid'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof StudysetRequest
     */
    'studies'?: Array<any>;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof StudysetRequest
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof StudysetRequest
     */
    'public'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StudysetRequest
     */
    'level'?: StudysetRequestLevelEnum;
}

export const StudysetRequestLevelEnum = {
    Group: 'group',
    Meta: 'meta'
} as const;

export type StudysetRequestLevelEnum = typeof StudysetRequestLevelEnum[keyof typeof StudysetRequestLevelEnum];

/**
 * 
 * @export
 * @interface StudysetRequestRelationships
 */
export interface StudysetRequestRelationships {
    /**
     * 
     * @type {Array<any>}
     * @memberof StudysetRequestRelationships
     */
    'studies'?: Array<any>;
}
/**
 * 
 * @export
 * @interface StudysetReturn
 */
export interface StudysetReturn {
    /**
     * Descriptive and human readable name of the studyset.
     * @type {string}
     * @memberof StudysetReturn
     */
    'name'?: string | null;
    /**
     * A longform description of the studyset.
     * @type {string}
     * @memberof StudysetReturn
     */
    'description'?: string | null;
    /**
     * The journal/source the studyset is connected to if the studyset was published.
     * @type {string}
     * @memberof StudysetReturn
     */
    'publication'?: string | null;
    /**
     * A DOI connected to the published studyset (may change to being automatically created so each studyset connected to a successful meta-analysis gets a DOI).
     * @type {string}
     * @memberof StudysetReturn
     */
    'doi'?: string | null;
    /**
     * If the article connected to the studyset was published on PubMed, then link the ID here.
     * @type {string}
     * @memberof StudysetReturn
     */
    'pmid'?: string | null;
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof StudysetReturn
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof StudysetReturn
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof StudysetReturn
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof StudysetReturn
     */
    'public'?: boolean;
    /**
     * who owns the resource
     * @type {string}
     * @memberof StudysetReturn
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof StudysetReturn
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'source_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'source_updated_at'?: string | null;
    /**
     * 
     * @type {StudysetReturnRelationshipsStudies}
     * @memberof StudysetReturn
     */
    'studies'?: StudysetReturnRelationshipsStudies;
    /**
     * 
     * @type {string}
     * @memberof StudysetReturn
     */
    'level'?: StudysetReturnLevelEnum;
}

export const StudysetReturnLevelEnum = {
    Group: 'group',
    Meta: 'meta'
} as const;

export type StudysetReturnLevelEnum = typeof StudysetReturnLevelEnum[keyof typeof StudysetReturnLevelEnum];

/**
 * 
 * @export
 * @interface StudysetReturnRelationships
 */
export interface StudysetReturnRelationships {
    /**
     * 
     * @type {StudysetReturnRelationshipsStudies}
     * @memberof StudysetReturnRelationships
     */
    'studies'?: StudysetReturnRelationshipsStudies;
}
/**
 * @type StudysetReturnRelationshipsStudies
 * @export
 */
export type StudysetReturnRelationshipsStudies = Array<StudyReturn> | Array<string>;

/**
 * 
 * @export
 * @interface StudysetsIdGet404Response
 */
export interface StudysetsIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdGet404Response
     */
    'detail'?: string;
    /**
     * 
     * @type {number}
     * @memberof StudysetsIdGet404Response
     */
    'status'?: StudysetsIdGet404ResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdGet404Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdGet404Response
     */
    'type'?: string;
}

export const StudysetsIdGet404ResponseStatusEnum = {
    NUMBER_404: 404
} as const;

export type StudysetsIdGet404ResponseStatusEnum = typeof StudysetsIdGet404ResponseStatusEnum[keyof typeof StudysetsIdGet404ResponseStatusEnum];

/**
 * 
 * @export
 * @interface StudysetsIdPut422Response
 */
export interface StudysetsIdPut422Response {
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdPut422Response
     */
    'detail'?: string;
    /**
     * 
     * @type {number}
     * @memberof StudysetsIdPut422Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdPut422Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudysetsIdPut422Response
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * full name of user
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'neuroid'?: string;
}
/**
 * 
 * @export
 * @interface UserList
 */
export interface UserList {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserList
     */
    'results'?: Array<User>;
    /**
     * 
     * @type {Metadata}
     * @memberof UserList
     */
    'metadata'?: Metadata;
}
/**
 * common resource attributes
 * @export
 * @interface UserResourceAttributes
 */
export interface UserResourceAttributes {
    /**
     * who owns the resource
     * @type {string}
     * @memberof UserResourceAttributes
     */
    'user'?: string | null;
    /**
     * human readable username
     * @type {string}
     * @memberof UserResourceAttributes
     */
    'username'?: string | null;
}
/**
 * common resource attributes not tied to a specific user
 * @export
 * @interface UserlessResourceAttributes
 */
export interface UserlessResourceAttributes {
    /**
     * time the resource was created on the database
     * @type {string}
     * @memberof UserlessResourceAttributes
     */
    'created_at'?: string;
    /**
     * when the resource was last modified/updated.
     * @type {string}
     * @memberof UserlessResourceAttributes
     */
    'updated_at'?: string | null;
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof UserlessResourceAttributes
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof UserlessResourceAttributes
     */
    'public'?: boolean;
}
/**
 * common resource attributes
 * @export
 * @interface WriteableResourceAttributes
 */
export interface WriteableResourceAttributes {
    /**
     * short UUID specifying the location of this resource
     * @type {string}
     * @memberof WriteableResourceAttributes
     */
    'id'?: string;
    /**
     * whether the resource is listed in public searches or not
     * @type {boolean}
     * @memberof WriteableResourceAttributes
     */
    'public'?: boolean;
}

/**
 * AnalysesApi - axios parameter creator
 * @export
 */
export const AnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdDelete', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdGet', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdPut: async (id: string, analysisRequest?: AnalysisRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdPut', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesPost: async (analysisRequest?: AnalysisRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdGet', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut: async (id: string, noteCollectionRequest?: NoteCollectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdPut', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost: async (noteCollectionRequest?: Array<NoteCollectionRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysesApi - functional programming interface
 * @export
 */
export const AnalysesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysesApiAxiosParamCreator(configuration)
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesGet(search, sort, page, desc, pageSize, name, description, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdPut(id, analysisRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesPost(analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesPost(analysisRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdPut(id, noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteCollectionReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesPost(noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalysesApi - factory interface
 * @export
 */
export const AnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysesApiFp(configuration)
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: any): AxiosPromise<AnalysisList> {
            return localVarFp.analysesGet(search, sort, page, desc, pageSize, name, description, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.analysesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesIdPut(id, analysisRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesPost(analysisRequest?: AnalysisRequest, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesPost(analysisRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet(options?: any): AxiosPromise<NoteCollectionList> {
            return localVarFp.annotationAnalysesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet(id: string, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: any): AxiosPromise<Array<NoteCollectionReturn>> {
            return localVarFp.annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysesApi - object-oriented interface
 * @export
 * @class AnalysesApi
 * @extends {BaseAPI}
 */
export class AnalysesApi extends BaseAPI {
    /**
     * List all analyses performed across studies.
     * @summary GET list of analyses
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).analysesGet(search, sort, page, desc, pageSize, name, description, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an analysis
     * @summary DELETE an analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public analysesIdDelete(id: string, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).analysesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Information pertaining to a particular analysis within a study.
     * @summary GET an analysis
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public analysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).analysesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing analysis.
     * @summary PUT/update an analysis
     * @param {string} id 
     * @param {AnalysisRequest} [analysisRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).analysesIdPut(id, analysisRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an analysis
     * @summary POST/create an analysis
     * @param {AnalysisRequest} [analysisRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public analysesPost(analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).analysesPost(analysisRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotation analyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public annotationAnalysesGet(options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).annotationAnalysesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).annotationAnalysesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your PUT endpoint
     * @param {string} id 
     * @param {NoteCollectionRequest} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
     * @summary Your POST endpoint
     * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig) {
        return AnalysesApiFp(this.configuration).annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdGet', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut: async (id: string, noteCollectionRequest?: NoteCollectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdPut', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost: async (noteCollectionRequest?: Array<NoteCollectionRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet: async (studysetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studysetId !== undefined) {
                localVarQueryParameter['studyset_id'] = studysetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdDelete', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet: async (id: string, _export?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdGet', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_export !== undefined) {
                localVarQueryParameter['export'] = _export;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut: async (id: string, annotationRequest?: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdPut', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost: async (source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdPut(id, noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteCollectionReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesPost(noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsGet(studysetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsGet(studysetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdGet(id: string, _export?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdGet(id, _export, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdPut(id, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsPost(source, sourceId, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet(options?: any): AxiosPromise<NoteCollectionList> {
            return localVarFp.annotationAnalysesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet(id: string, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: any): AxiosPromise<Array<NoteCollectionReturn>> {
            return localVarFp.annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet(studysetId?: string, options?: any): AxiosPromise<AnnotationList> {
            return localVarFp.annotationsGet(studysetId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.annotationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet(id: string, _export?: boolean, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdGet(id, _export, options).then((request) => request(axios, basePath));
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdPut(id, annotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsPost(source, sourceId, annotationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
    /**
     * 
     * @summary Get annotation analyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationAnalysesGet(options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationAnalysesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationAnalysesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your PUT endpoint
     * @param {string} id 
     * @param {NoteCollectionRequest} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
     * @summary Your POST endpoint
     * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get annotations for an available studyset
     * @summary Your GET endpoint
     * @param {string} [studysetId] see all annotations connected to this studyset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsGet(studysetId?: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsGet(studysetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete annotation
     * @summary DELETE an annotation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get an individual annotation
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [_export] return endpoint data in consumable/readable format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsIdGet(id: string, _export?: boolean, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsIdGet(id, _export, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * edit an existing annotation
     * @summary Update an annotation
     * @param {string} id 
     * @param {AnnotationRequest} [annotationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsIdPut(id, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an annotation
     * @summary Post Annotation
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {AnnotationRequest} [annotationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).annotationsPost(source, sourceId, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConditionsApi - axios parameter creator
 * @export
 */
export const ConditionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdDelete', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdGet', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdPut: async (id: string, conditionRequest?: ConditionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdPut', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsPost: async (conditionRequest?: ConditionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionsApi - functional programming interface
 * @export
 */
export const ConditionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsGet(search, sort, page, desc, pageSize, name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdPut(id, conditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsPost(conditionRequest?: ConditionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsPost(conditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConditionsApi - factory interface
 * @export
 */
export const ConditionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionsApiFp(configuration)
    return {
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: any): AxiosPromise<ConditionList> {
            return localVarFp.conditionsGet(search, sort, page, desc, pageSize, name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.conditionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdGet(id: string, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsIdPut(id, conditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsPost(conditionRequest?: ConditionRequest, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsPost(conditionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionsApi - object-oriented interface
 * @export
 * @class ConditionsApi
 * @extends {BaseAPI}
 */
export class ConditionsApi extends BaseAPI {
    /**
     * Get all conditions
     * @summary GET Conditions
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionsApi
     */
    public conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: AxiosRequestConfig) {
        return ConditionsApiFp(this.configuration).conditionsGet(search, sort, page, desc, pageSize, name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a condition
     * @summary DELETE a condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionsApi
     */
    public conditionsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ConditionsApiFp(this.configuration).conditionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
     * @summary GET a condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionsApi
     */
    public conditionsIdGet(id: string, options?: AxiosRequestConfig) {
        return ConditionsApiFp(this.configuration).conditionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update a condition
     * @summary PUT/update a condition
     * @param {string} id 
     * @param {ConditionRequest} [conditionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionsApi
     */
    public conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: AxiosRequestConfig) {
        return ConditionsApiFp(this.configuration).conditionsIdPut(id, conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a condition
     * @summary POST/Create a condition
     * @param {ConditionRequest} [conditionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionsApi
     */
    public conditionsPost(conditionRequest?: ConditionRequest, options?: AxiosRequestConfig) {
        return ConditionsApiFp(this.configuration).conditionsPost(conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (analysisName !== undefined) {
                localVarQueryParameter['analysis_name'] = analysisName;
            }

            if (valueType !== undefined) {
                localVarQueryParameter['value_type'] = valueType;
            }

            if (space !== undefined) {
                localVarQueryParameter['space'] = space;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdDelete', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdGet', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdPut: async (id: string, imageRequest?: ImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdPut', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost: async (imageRequest?: ImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdPut(id: string, imageRequest?: ImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdPut(id, imageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesPost(imageRequest?: ImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesPost(imageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: any): AxiosPromise<ImageList> {
            return localVarFp.imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.imagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdGet(id: string, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdPut(id: string, imageRequest?: ImageRequest, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesIdPut(id, imageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(imageRequest?: ImageRequest, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesPost(imageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * Retrieve and list images.
     * @summary GET a list of images
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [filename] search filename field
     * @param {string} [analysisName] search analysis_name field
     * @param {string} [valueType] search value_type field
     * @param {string} [space] search space field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an image
     * @summary DELETE an image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve information about a particular image from an analysis.
     * @summary GET an image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesIdGet(id: string, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a specific image.
     * @summary PUT/update an image
     * @param {string} id 
     * @param {ImageRequest} [imageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesIdPut(id: string, imageRequest?: ImageRequest, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imagesIdPut(id, imageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an image
     * @summary POST/create an image
     * @param {ImageRequest} [imageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesPost(imageRequest?: ImageRequest, options?: AxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imagesPost(imageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointsApi - axios parameter creator
 * @export
 */
export const PointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdDelete', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdGet', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdPut: async (id: string, pointRequest?: PointRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdPut', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost: async (pointRequest?: PointRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsApi - functional programming interface
 * @export
 */
export const PointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsApiAxiosParamCreator(configuration)
    return {
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdPut(id: string, pointRequest?: PointRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdPut(id, pointRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsPost(pointRequest?: PointRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsPost(pointRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointsApi - factory interface
 * @export
 */
export const PointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsApiFp(configuration)
    return {
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet(options?: any): AxiosPromise<PointList> {
            return localVarFp.pointsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.pointsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdGet(id: string, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdPut(id: string, pointRequest?: PointRequest, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsIdPut(id, pointRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost(pointRequest?: PointRequest, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsPost(pointRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsApi - object-oriented interface
 * @export
 * @class PointsApi
 * @extends {BaseAPI}
 */
export class PointsApi extends BaseAPI {
    /**
     * list points in database
     * @summary Get Points
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsGet(options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a point
     * @summary DELETE a point
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsIdDelete(id: string, options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Information about a particular MRI coordinate
     * @summary GET a point
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsIdGet(id: string, options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a particular MRI coordinate.
     * @summary PUT/update a point
     * @param {string} id 
     * @param {PointRequest} [pointRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsIdPut(id: string, pointRequest?: PointRequest, options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsIdPut(id, pointRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add a point to an analysis
     * @summary POST Points
     * @param {PointRequest} [pointRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsPost(pointRequest?: PointRequest, options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsPost(pointRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdDelete', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdGet: async (id: string, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdGet', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdPut: async (id: string, analysisRequest?: AnalysisRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('analysesIdPut', 'id', id)
            const localVarPath = `/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesPost: async (analysisRequest?: AnalysisRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysisRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdGet', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut: async (id: string, noteCollectionRequest?: NoteCollectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationAnalysesIdPut', 'id', id)
            const localVarPath = `/annotation-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost: async (noteCollectionRequest?: Array<NoteCollectionRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation-analyses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteCollectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet: async (studysetId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (studysetId !== undefined) {
                localVarQueryParameter['studyset_id'] = studysetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdDelete', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet: async (id: string, _export?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdGet', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_export !== undefined) {
                localVarQueryParameter['export'] = _export;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut: async (id: string, annotationRequest?: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('annotationsIdPut', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost: async (source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (authors !== undefined) {
                localVarQueryParameter['authors'] = authors;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['data_type'] = dataType;
            }

            if (publication !== undefined) {
                localVarQueryParameter['publication'] = publication;
            }

            if (pmid !== undefined) {
                localVarQueryParameter['pmid'] = pmid;
            }

            if (doi !== undefined) {
                localVarQueryParameter['doi'] = doi;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdGet: async (id: string, flat?: boolean, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('baseStudiesIdGet', 'id', id)
            const localVarPath = `/base-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdPut: async (id: string, baseStudy?: BaseStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('baseStudiesIdPut', 'id', id)
            const localVarPath = `/base-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesPost: async (baseStudiesPostRequest?: BaseStudiesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseStudiesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdDelete', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdGet', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdPut: async (id: string, conditionRequest?: ConditionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('conditionsIdPut', 'id', id)
            const localVarPath = `/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsPost: async (conditionRequest?: ConditionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/conditions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (analysisName !== undefined) {
                localVarQueryParameter['analysis_name'] = analysisName;
            }

            if (valueType !== undefined) {
                localVarQueryParameter['value_type'] = valueType;
            }

            if (space !== undefined) {
                localVarQueryParameter['space'] = space;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdDelete', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdGet', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdPut: async (id: string, imageRequest?: ImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesIdPut', 'id', id)
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost: async (imageRequest?: ImageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdDelete', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdGet', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdPut: async (id: string, pointRequest?: PointRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointsIdPut', 'id', id)
            const localVarPath = `/points/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost: async (pointRequest?: PointRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }

            if (unique !== undefined) {
                localVarQueryParameter['unique'] = unique;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (authors !== undefined) {
                localVarQueryParameter['authors'] = authors;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['data_type'] = dataType;
            }

            if (studysetOwner !== undefined) {
                localVarQueryParameter['studyset_owner'] = studysetOwner;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (pmid !== undefined) {
                localVarQueryParameter['pmid'] = pmid;
            }

            if (doi !== undefined) {
                localVarQueryParameter['doi'] = doi;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdDelete', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdGet: async (id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdGet', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (studysetOwner !== undefined) {
                localVarQueryParameter['studyset_owner'] = studysetOwner;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdPut: async (id: string, studyRequest?: StudyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdPut', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesPost: async (source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdDelete', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet: async (id: string, nested?: boolean, gzip?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdGet', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (gzip !== undefined) {
                localVarQueryParameter['gzip'] = gzip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut: async (id: string, studysetRequest?: StudysetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdPut', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost: async (studysetRequest?: StudysetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesGet(search, sort, page, desc, pageSize, name, description, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdGet(id, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesIdPut(id, analysisRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesPost(analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysisReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesPost(analysisRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteCollectionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesIdPut(id, noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteCollectionReturn>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationAnalysesPost(noteCollectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsGet(studysetId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsGet(studysetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdGet(id: string, _export?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdGet(id, _export, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsIdPut(id, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annotationsPost(source, sourceId, annotationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesIdGet(id, flat, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesIdPut(id, baseStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudiesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesPost(baseStudiesPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsGet(search, sort, page, desc, pageSize, name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsIdPut(id, conditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conditionsPost(conditionRequest?: ConditionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conditionsPost(conditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesIdPut(id: string, imageRequest?: ImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesIdPut(id, imageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesPost(imageRequest?: ImageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesPost(imageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsIdPut(id: string, pointRequest?: PointRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsIdPut(id, pointRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsPost(pointRequest?: PointRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsPost(pointRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdGet(id, nested, studysetOwner, flat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdPut(id: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdPut(id, studyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesPost(source, sourceId, studyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdGet(id, nested, gzip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdPut(id, studysetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsPost(studysetRequest?: StudysetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsPost(studysetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * List all analyses performed across studies.
         * @summary GET list of analyses
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: any): AxiosPromise<AnalysisList> {
            return localVarFp.analysesGet(search, sort, page, desc, pageSize, name, description, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an analysis
         * @summary DELETE an analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.analysesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Information pertaining to a particular analysis within a study.
         * @summary GET an analysis
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdGet(id: string, nested?: boolean, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesIdGet(id, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing analysis.
         * @summary PUT/update an analysis
         * @param {string} id 
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesIdPut(id, analysisRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * create an analysis
         * @summary POST/create an analysis
         * @param {AnalysisRequest} [analysisRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesPost(analysisRequest?: AnalysisRequest, options?: any): AxiosPromise<AnalysisReturn> {
            return localVarFp.analysesPost(analysisRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get annotation analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesGet(options?: any): AxiosPromise<NoteCollectionList> {
            return localVarFp.annotationAnalysesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdGet(id: string, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your PUT endpoint
         * @param {string} id 
         * @param {NoteCollectionRequest} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: any): AxiosPromise<NoteCollectionReturn> {
            return localVarFp.annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
         * @summary Your POST endpoint
         * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: any): AxiosPromise<Array<NoteCollectionReturn>> {
            return localVarFp.annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * get annotations for an available studyset
         * @summary Your GET endpoint
         * @param {string} [studysetId] see all annotations connected to this studyset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsGet(studysetId?: string, options?: any): AxiosPromise<AnnotationList> {
            return localVarFp.annotationsGet(studysetId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete annotation
         * @summary DELETE an annotation
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.annotationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get an individual annotation
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [_export] return endpoint data in consumable/readable format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdGet(id: string, _export?: boolean, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdGet(id, _export, options).then((request) => request(axios, basePath));
        },
        /**
         * edit an existing annotation
         * @summary Update an annotation
         * @param {string} id 
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsIdPut(id, annotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an annotation
         * @summary Post Annotation
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {AnnotationRequest} [annotationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: any): AxiosPromise<AnnotationReturn> {
            return localVarFp.annotationsPost(source, sourceId, annotationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: any): AxiosPromise<BaseStudyList> {
            return localVarFp.baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: any): AxiosPromise<BaseStudyReturn> {
            return localVarFp.baseStudiesIdGet(id, flat, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: any): AxiosPromise<BaseStudyReturn> {
            return localVarFp.baseStudiesIdPut(id, baseStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: any): AxiosPromise<BaseStudiesPost200Response> {
            return localVarFp.baseStudiesPost(baseStudiesPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all conditions
         * @summary GET Conditions
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: any): AxiosPromise<ConditionList> {
            return localVarFp.conditionsGet(search, sort, page, desc, pageSize, name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a condition
         * @summary DELETE a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.conditionsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
         * @summary GET a condition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdGet(id: string, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update a condition
         * @summary PUT/update a condition
         * @param {string} id 
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsIdPut(id, conditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a condition
         * @summary POST/Create a condition
         * @param {ConditionRequest} [conditionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conditionsPost(conditionRequest?: ConditionRequest, options?: any): AxiosPromise<ConditionReturn> {
            return localVarFp.conditionsPost(conditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve and list images.
         * @summary GET a list of images
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [filename] search filename field
         * @param {string} [analysisName] search analysis_name field
         * @param {string} [valueType] search value_type field
         * @param {string} [space] search space field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: any): AxiosPromise<ImageList> {
            return localVarFp.imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an image
         * @summary DELETE an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.imagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve information about a particular image from an analysis.
         * @summary GET an image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdGet(id: string, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific image.
         * @summary PUT/update an image
         * @param {string} id 
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesIdPut(id: string, imageRequest?: ImageRequest, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesIdPut(id, imageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an image
         * @summary POST/create an image
         * @param {ImageRequest} [imageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPost(imageRequest?: ImageRequest, options?: any): AxiosPromise<ImageReturn> {
            return localVarFp.imagesPost(imageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * list points in database
         * @summary Get Points
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet(options?: any): AxiosPromise<PointList> {
            return localVarFp.pointsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * delete a point
         * @summary DELETE a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.pointsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Information about a particular MRI coordinate
         * @summary GET a point
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdGet(id: string, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a particular MRI coordinate.
         * @summary PUT/update a point
         * @param {string} id 
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsIdPut(id: string, pointRequest?: PointRequest, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsIdPut(id, pointRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * add a point to an analysis
         * @summary POST Points
         * @param {PointRequest} [pointRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsPost(pointRequest?: PointRequest, options?: any): AxiosPromise<PointReturn> {
            return localVarFp.pointsPost(pointRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: any): AxiosPromise<StudyList> {
            return localVarFp.studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.studiesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesIdGet(id, nested, studysetOwner, flat, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdPut(id: string, studyRequest?: StudyRequest, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesIdPut(id, studyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesPost(source, sourceId, studyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.studysetsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdGet(id, nested, gzip, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdPut(id, studysetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost(studysetRequest?: StudysetRequest, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsPost(studysetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * List all analyses performed across studies.
     * @summary GET list of analyses
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public analysesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, nested?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).analysesGet(search, sort, page, desc, pageSize, name, description, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an analysis
     * @summary DELETE an analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public analysesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).analysesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Information pertaining to a particular analysis within a study.
     * @summary GET an analysis
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public analysesIdGet(id: string, nested?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).analysesIdGet(id, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing analysis.
     * @summary PUT/update an analysis
     * @param {string} id 
     * @param {AnalysisRequest} [analysisRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public analysesIdPut(id: string, analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).analysesIdPut(id, analysisRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create an analysis
     * @summary POST/create an analysis
     * @param {AnalysisRequest} [analysisRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public analysesPost(analysisRequest?: AnalysisRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).analysesPost(analysisRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get annotation analyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationAnalysesGet(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationAnalysesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationAnalysesIdGet(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationAnalysesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your PUT endpoint
     * @param {string} id 
     * @param {NoteCollectionRequest} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationAnalysesIdPut(id: string, noteCollectionRequest?: NoteCollectionRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationAnalysesIdPut(id, noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint does not allow for creation, only modification of existing annotation-analyses. If you wish to create an annotation-analysis, post to the annotations endpoint and/or add the analysis to the appropriate study in the studyset, and the annotation-analysis will be created automatically. 
     * @summary Your POST endpoint
     * @param {Array<NoteCollectionRequest>} [noteCollectionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationAnalysesPost(noteCollectionRequest?: Array<NoteCollectionRequest>, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationAnalysesPost(noteCollectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get annotations for an available studyset
     * @summary Your GET endpoint
     * @param {string} [studysetId] see all annotations connected to this studyset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationsGet(studysetId?: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationsGet(studysetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete annotation
     * @summary DELETE an annotation
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get an individual annotation
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [_export] return endpoint data in consumable/readable format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationsIdGet(id: string, _export?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationsIdGet(id, _export, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * edit an existing annotation
     * @summary Update an annotation
     * @param {string} id 
     * @param {AnnotationRequest} [annotationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationsIdPut(id: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationsIdPut(id, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an annotation
     * @summary Post Annotation
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {AnnotationRequest} [annotationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public annotationsPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, annotationRequest?: AnnotationRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).annotationsPost(source, sourceId, annotationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {string} [authors] search authors
     * @param {'group' | 'meta'} [level] select between studies with group results or meta results
     * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
     * @param {string} [publication] search for papers from a particular journal
     * @param {string} [pmid] search for particular pmid
     * @param {string} [doi] search for study with specific doi
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).baseStudiesIdGet(id, flat, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {BaseStudy} [baseStudy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).baseStudiesIdPut(id, baseStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).baseStudiesPost(baseStudiesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all conditions
     * @summary GET Conditions
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public conditionsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).conditionsGet(search, sort, page, desc, pageSize, name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a condition
     * @summary DELETE a condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public conditionsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).conditionsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a condition (e.g., 2-back) that can be used in contrasts (e.g., 2-back - 1-back)
     * @summary GET a condition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public conditionsIdGet(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).conditionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update a condition
     * @summary PUT/update a condition
     * @param {string} id 
     * @param {ConditionRequest} [conditionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public conditionsIdPut(id: string, conditionRequest?: ConditionRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).conditionsIdPut(id, conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a condition
     * @summary POST/Create a condition
     * @param {ConditionRequest} [conditionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public conditionsPost(conditionRequest?: ConditionRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).conditionsPost(conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve and list images.
     * @summary GET a list of images
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [filename] search filename field
     * @param {string} [analysisName] search analysis_name field
     * @param {string} [valueType] search value_type field
     * @param {string} [space] search space field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public imagesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, filename?: string, analysisName?: string, valueType?: string, space?: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).imagesGet(search, sort, page, desc, pageSize, filename, analysisName, valueType, space, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete an image
     * @summary DELETE an image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public imagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).imagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve information about a particular image from an analysis.
     * @summary GET an image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public imagesIdGet(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).imagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a specific image.
     * @summary PUT/update an image
     * @param {string} id 
     * @param {ImageRequest} [imageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public imagesIdPut(id: string, imageRequest?: ImageRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).imagesIdPut(id, imageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an image
     * @summary POST/create an image
     * @param {ImageRequest} [imageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public imagesPost(imageRequest?: ImageRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).imagesPost(imageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list points in database
     * @summary Get Points
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pointsGet(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pointsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a point
     * @summary DELETE a point
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pointsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pointsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Information about a particular MRI coordinate
     * @summary GET a point
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pointsIdGet(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pointsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a particular MRI coordinate.
     * @summary PUT/update a point
     * @param {string} id 
     * @param {PointRequest} [pointRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pointsIdPut(id: string, pointRequest?: PointRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pointsIdPut(id, pointRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * add a point to an analysis
     * @summary POST Points
     * @param {PointRequest} [pointRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public pointsPost(pointRequest?: PointRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).pointsPost(pointRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List studies
     * @summary GET a list of studies
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {any} [unique] whether to list clones with originals
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [authors] search authors
     * @param {string} [userId] user id you want to filter by
     * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
     * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
     * @param {'group' | 'meta'} [level] select between studies with group results or meta results
     * @param {string} [pmid] search for particular pmid
     * @param {string} [doi] search for study with specific doi
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a study
     * @summary DELETE a study
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studiesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studiesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a study.
     * @summary GET a study
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studiesIdGet(id, nested, studysetOwner, flat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a study.
     * @summary PUT/update a study
     * @param {string} id 
     * @param {StudyRequest} [studyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studiesIdPut(id: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studiesIdPut(id, studyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a study
     * @summary POST/create a study
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {StudyRequest} [studyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studiesPost(source, sourceId, studyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a studyset
     * @summary DELETE a studyset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studysetsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studysetsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the information of a studyset with the matching studyset ID.
     * @summary GET a studyset
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {boolean} [gzip] return the content as gzipped content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studysetsIdGet(id, nested, gzip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a studyset.
     * @summary PUT/update a studyset
     * @param {string} id 
     * @param {StudysetRequest} [studysetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studysetsIdPut(id, studysetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a studyset.
     * @summary POST/create a studyset
     * @param {StudysetRequest} [studysetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public studysetsPost(studysetRequest?: StudysetRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).studysetsPost(studysetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudiesApi - axios parameter creator
 * @export
 */
export const StudiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (authors !== undefined) {
                localVarQueryParameter['authors'] = authors;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['data_type'] = dataType;
            }

            if (publication !== undefined) {
                localVarQueryParameter['publication'] = publication;
            }

            if (pmid !== undefined) {
                localVarQueryParameter['pmid'] = pmid;
            }

            if (doi !== undefined) {
                localVarQueryParameter['doi'] = doi;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdGet: async (id: string, flat?: boolean, info?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('baseStudiesIdGet', 'id', id)
            const localVarPath = `/base-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }

            if (info !== undefined) {
                localVarQueryParameter['info'] = info;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdPut: async (id: string, baseStudy?: BaseStudy, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('baseStudiesIdPut', 'id', id)
            const localVarPath = `/base-studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseStudy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesPost: async (baseStudiesPostRequest?: BaseStudiesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseStudiesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }

            if (unique !== undefined) {
                localVarQueryParameter['unique'] = unique;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (authors !== undefined) {
                localVarQueryParameter['authors'] = authors;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['data_type'] = dataType;
            }

            if (studysetOwner !== undefined) {
                localVarQueryParameter['studyset_owner'] = studysetOwner;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (pmid !== undefined) {
                localVarQueryParameter['pmid'] = pmid;
            }

            if (doi !== undefined) {
                localVarQueryParameter['doi'] = doi;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdDelete', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdGet: async (id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdGet', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (studysetOwner !== undefined) {
                localVarQueryParameter['studyset_owner'] = studysetOwner;
            }

            if (flat !== undefined) {
                localVarQueryParameter['flat'] = flat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdPut: async (id: string, studyRequest?: StudyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studiesIdPut', 'id', id)
            const localVarPath = `/studies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesPost: async (source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studies/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudiesApi - functional programming interface
 * @export
 */
export const StudiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudiesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesIdGet(id, flat, info, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesIdPut(id, baseStudy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseStudiesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baseStudiesPost(baseStudiesPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdGet(id, nested, studysetOwner, flat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesIdPut(id: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesIdPut(id, studyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studiesPost(source, sourceId, studyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudiesApi - factory interface
 * @export
 */
export const StudiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudiesApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [authors] search authors
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [publication] search for papers from a particular journal
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: any): AxiosPromise<BaseStudyList> {
            return localVarFp.baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Your GET endpoint
         * @param {string} id 
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: any): AxiosPromise<BaseStudyReturn> {
            return localVarFp.baseStudiesIdGet(id, flat, info, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {BaseStudy} [baseStudy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: any): AxiosPromise<BaseStudyReturn> {
            return localVarFp.baseStudiesIdPut(id, baseStudy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: any): AxiosPromise<BaseStudiesPost200Response> {
            return localVarFp.baseStudiesPost(baseStudiesPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * List studies
         * @summary GET a list of studies
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {'group' | 'meta'} [level] select between studies with group results or meta results
         * @param {string} [pmid] search for particular pmid
         * @param {string} [doi] search for study with specific doi
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: any): AxiosPromise<StudyList> {
            return localVarFp.studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a study
         * @summary DELETE a study
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.studiesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a study.
         * @summary GET a study
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
         * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesIdGet(id, nested, studysetOwner, flat, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a study.
         * @summary PUT/update a study
         * @param {string} id 
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesIdPut(id: string, studyRequest?: StudyRequest, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesIdPut(id, studyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a study
         * @summary POST/create a study
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {StudyRequest} [studyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: any): AxiosPromise<StudyReturn> {
            return localVarFp.studiesPost(source, sourceId, studyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudiesApi - object-oriented interface
 * @export
 * @class StudiesApi
 * @extends {BaseAPI}
 */
export class StudiesApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {string} [authors] search authors
     * @param {'group' | 'meta'} [level] select between studies with group results or meta results
     * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
     * @param {string} [publication] search for papers from a particular journal
     * @param {string} [pmid] search for particular pmid
     * @param {string} [doi] search for study with specific doi
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public baseStudiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, name?: string, description?: string, authors?: string, level?: 'group' | 'meta', dataType?: 'coordinate' | 'image' | 'both', publication?: string, pmid?: string, doi?: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).baseStudiesGet(search, sort, page, desc, pageSize, name, description, authors, level, dataType, publication, pmid, doi, flat, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Your GET endpoint
     * @param {string} id 
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {boolean} [info] show additional for endpoint-object relationships without being fully nested. Incompatible with nested
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public baseStudiesIdGet(id: string, flat?: boolean, info?: boolean, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).baseStudiesIdGet(id, flat, info, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {BaseStudy} [baseStudy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public baseStudiesIdPut(id: string, baseStudy?: BaseStudy, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).baseStudiesIdPut(id, baseStudy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {BaseStudiesPostRequest} [baseStudiesPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public baseStudiesPost(baseStudiesPostRequest?: BaseStudiesPostRequest, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).baseStudiesPost(baseStudiesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List studies
     * @summary GET a list of studies
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {any} [unique] whether to list clones with originals
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [authors] search authors
     * @param {string} [userId] user id you want to filter by
     * @param {'coordinate' | 'image' | 'both'} [dataType] whether searching for studies that contain coordinates, images, or both
     * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
     * @param {'group' | 'meta'} [level] select between studies with group results or meta results
     * @param {string} [pmid] search for particular pmid
     * @param {string} [doi] search for study with specific doi
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public studiesGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, dataType?: 'coordinate' | 'image' | 'both', studysetOwner?: string, level?: 'group' | 'meta', pmid?: string, doi?: string, flat?: boolean, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).studiesGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, dataType, studysetOwner, level, pmid, doi, flat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a study
     * @summary DELETE a study
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public studiesIdDelete(id: string, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).studiesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a study.
     * @summary GET a study
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {string} [studysetOwner] for all studies filter which studysets are listed based on who owns the studyset
     * @param {boolean} [flat] do not return any embedded relationships. When set, it is incompatible with nested. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public studiesIdGet(id: string, nested?: boolean, studysetOwner?: string, flat?: boolean, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).studiesIdGet(id, nested, studysetOwner, flat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a study.
     * @summary PUT/update a study
     * @param {string} id 
     * @param {StudyRequest} [studyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public studiesIdPut(id: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).studiesIdPut(id, studyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a study
     * @summary POST/create a study
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {StudyRequest} [studyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public studiesPost(source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', sourceId?: string, studyRequest?: StudyRequest, options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).studiesPost(source, sourceId, studyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudysetsApi - axios parameter creator
 * @export
 */
export const StudysetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of studysets.
         * @summary GET a list of studysets
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet: async (search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['source_id'] = sourceId;
            }

            if (unique !== undefined) {
                localVarQueryParameter['unique'] = unique;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (authors !== undefined) {
                localVarQueryParameter['authors'] = authors;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdDelete', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet: async (id: string, nested?: boolean, gzip?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdGet', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }

            if (gzip !== undefined) {
                localVarQueryParameter['gzip'] = gzip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut: async (id: string, studysetRequest?: StudysetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('studysetsIdPut', 'id', id)
            const localVarPath = `/studysets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost: async (studysetRequest?: StudysetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/studysets/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studysetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudysetsApi - functional programming interface
 * @export
 */
export const StudysetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudysetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a list of studysets.
         * @summary GET a list of studysets
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdGet(id, nested, gzip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsIdPut(id, studysetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studysetsPost(studysetRequest?: StudysetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudysetReturn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studysetsPost(studysetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudysetsApi - factory interface
 * @export
 */
export const StudysetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudysetsApiFp(configuration)
    return {
        /**
         * Get a list of studysets.
         * @summary GET a list of studysets
         * @param {string} [search] search for entries that contain the substring
         * @param {string} [sort] Parameter to sort results on
         * @param {number} [page] page of results
         * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
         * @param {number} [pageSize] number of results to show on a page
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {string} [name] search the name field for a term
         * @param {string} [description] search description field for a term
         * @param {string} [sourceId] id of the resource you are either filtering/copying on
         * @param {any} [unique] whether to list clones with originals
         * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
         * @param {string} [authors] search authors
         * @param {string} [userId] user id you want to filter by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, options?: any): AxiosPromise<StudysetList> {
            return localVarFp.studysetsGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a studyset
         * @summary DELETE a studyset
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.studysetsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the information of a studyset with the matching studyset ID.
         * @summary GET a studyset
         * @param {string} id 
         * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
         * @param {boolean} [gzip] return the content as gzipped content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdGet(id, nested, gzip, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a studyset.
         * @summary PUT/update a studyset
         * @param {string} id 
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsIdPut(id, studysetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a studyset.
         * @summary POST/create a studyset
         * @param {StudysetRequest} [studysetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studysetsPost(studysetRequest?: StudysetRequest, options?: any): AxiosPromise<StudysetReturn> {
            return localVarFp.studysetsPost(studysetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudysetsApi - object-oriented interface
 * @export
 * @class StudysetsApi
 * @extends {BaseAPI}
 */
export class StudysetsApi extends BaseAPI {
    /**
     * Get a list of studysets.
     * @summary GET a list of studysets
     * @param {string} [search] search for entries that contain the substring
     * @param {string} [sort] Parameter to sort results on
     * @param {number} [page] page of results
     * @param {boolean} [desc] sort results by descending order (as opposed to ascending order)
     * @param {number} [pageSize] number of results to show on a page
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {string} [name] search the name field for a term
     * @param {string} [description] search description field for a term
     * @param {string} [sourceId] id of the resource you are either filtering/copying on
     * @param {any} [unique] whether to list clones with originals
     * @param {'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery'} [source] the source of the resource you would like to filter/copy from
     * @param {string} [authors] search authors
     * @param {string} [userId] user id you want to filter by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsGet(search?: string, sort?: string, page?: number, desc?: boolean, pageSize?: number, nested?: boolean, name?: string, description?: string, sourceId?: string, unique?: any, source?: 'neurostore' | 'neurovault' | 'pubmed' | 'neurosynth' | 'neuroquery', authors?: string, userId?: string, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsGet(search, sort, page, desc, pageSize, nested, name, description, sourceId, unique, source, authors, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete a studyset
     * @summary DELETE a studyset
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsIdDelete(id: string, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the information of a studyset with the matching studyset ID.
     * @summary GET a studyset
     * @param {string} id 
     * @param {boolean} [nested] whether to show the URI to a resource (false) or to embed the object in the response (true)
     * @param {boolean} [gzip] return the content as gzipped content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsIdGet(id: string, nested?: boolean, gzip?: boolean, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsIdGet(id, nested, gzip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a studyset.
     * @summary PUT/update a studyset
     * @param {string} id 
     * @param {StudysetRequest} [studysetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsIdPut(id: string, studysetRequest?: StudysetRequest, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsIdPut(id, studysetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a studyset.
     * @summary POST/create a studyset
     * @param {StudysetRequest} [studysetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudysetsApi
     */
    public studysetsPost(studysetRequest?: StudysetRequest, options?: AxiosRequestConfig) {
        return StudysetsApiFp(this.configuration).studysetsPost(studysetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get list of users
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get an individual user
         * @summary Individual User Profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update an individual user
         * @summary Update Individual Profile
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut: async (id: string, user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPut', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a user
         * @summary 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JSON-Web-Token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * get list of users
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get an individual user
         * @summary Individual User Profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update an individual user
         * @summary Update Individual Profile
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPut(id: string, user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create a user
         * @summary 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * get list of users
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(options?: any): AxiosPromise<UserList> {
            return localVarFp.usersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get an individual user
         * @summary Individual User Profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * update an individual user
         * @summary Update Individual Profile
         * @param {string} id 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * create a user
         * @summary 
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.usersPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * get list of users
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get an individual user
     * @summary Individual User Profile
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersIdGet(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update an individual user
     * @summary Update Individual Profile
     * @param {string} id 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersIdPut(id: string, user?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a user
     * @summary 
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersPost(user?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


